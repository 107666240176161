import React from "react";

export default function Input(props) {

    return (
        <div>
            <input className="form-control" onBlur={props.handleBlur} type={props.type} name={props.name}
                id={props.id}
                value={props.inputValue} placeholder={props.placeholder}
                onChange={props.handleChange}
                {...props.specialProps}
            />
            <div className="text-danger">
                {props.errorMessage}
            </div>
        </div>
    )
}
