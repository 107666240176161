import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    message: null,
    type: null
};
const showNotification=(state, action) =>{
    return updateObject(state, {
        message: action.message,
        type: action.notificationType
    });
}
const hideNotification=(state, action) =>{
    return updateObject(state, {
        notification: null,
        type: null
    });
}

export function reducer(state = initialState, action){
    switch (action.type) {
        case actionTypes.SHOW_NOTIFICATION:
            return showNotification(state, action);
        case actionTypes.HIDE_NOTIFICATION:
            return hideNotification(state, action);
        default:
            return state;
    }
}
export default reducer;
