import React, {Component} from "react";
import {Link, Redirect} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import * as actions from "../store/actions";
import Spinner from "./layouts/Spinner";
import {checkValidity} from "../shared/validation";
import Input from "./layouts/Input";
import http from "../config/http-common";

const initialState = {
    formIsValid: false,
    successful:false,
    controls: {
        password: {
            value: "",
            validation: {
                required: true,
                minLength: 7
            },
            valid: false,
            touched: false
        },
        passwordRepeat: {
            value: "",
            validation: {
                required: true
            },
            valid: false,
            touched: false
        }
    }
};

class RestartPasswordComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {...initialState};
    }

    handleChange = (e) => {
        let updatedControls;
        if (e.target.name === 'password') {
            updatedControls = {
                ...this.state.controls,
                [e.target.name]: {
                    ...this.state.controls[e.target.name],
                    value: e.target.value,
                    valid: checkValidity(e.target.value, this.state.controls[e.target.name].validation),
                    touched: true
                }
            };
            if (updatedControls.password.valid) {
                updatedControls = {
                    ...updatedControls,
                    passwordRepeat: {
                        ...this.state.controls.passwordRepeat,
                        valid: this.state.controls.passwordRepeat.value === e.target.value
                    }
                }
            }
        }
        if (e.target.name === 'passwordRepeat') {
            updatedControls = {
                ...this.state.controls,
                [e.target.name]: {
                    ...this.state.controls[e.target.name],
                    value: e.target.value,
                    valid: checkValidity(e.target.value, this.state.controls[e.target.name].validation) && (e.target.value === this.state.controls.password.value),
                    touched: true
                }
            };
        }
        let formIsValid = true;
        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }
        this.setState({controls: updatedControls, formIsValid: formIsValid});
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            new_password: this.state.controls.password.value,
            new_password_repeat: this.state.controls.password.value
        }

        http.post("/restart-password/" + this.props.match.params.token, data)
            .then(data => {
                this.props.onNotify(data.data.message, "INFO");
                this.setState({successful:true});
            })
            .catch(error => {
                this.props.onNotify(error.response.data, "ERROR")
            })
    }


    render() {
        const {t} = this.props;
        const redirect = <Redirect to={"/"}/>

        const submitButton = this.state.formIsValid
            ? <button className={"btn_1 rounded full-width"}
                      onClick={this.handleSubmit}>{t("home.reset_password")}</button>
            : <button className={"btn_1_disabled rounded full-width"} disabled={true}
                      onClick={this.handleSubmit}>{t("home.reset_password")}</button>

        const restartPasswordScreen =
            <div id="register_bg">
                <nav id="menu" className="fake_menu"/>
                <div id="login">
                    <aside>
                        <figure>
                            <Link to="/">
                                <img src="../assets/img/logo_sticky.svg"
                                     style={{transform: "scale(2.5)"}} width="100" height="50"
                                     alt=""
                                     className="logo_sticky"/>
                            </Link>
                        </figure>
                        <br/>
                        <form>
                            <div className="form-group">
                                <Input type="password" id="password1" name="password"
                                       inputValue={this.state.controls.password.value}
                                       handleChange={this.handleChange}
                                       placeholder={t("user_settings.new_password")}
                                       errorMessage={!this.state.controls.password.valid && this.state.controls.password.touched ? t("form_validation.password") : ''}/>
                                <i className="icon_lock_alt"/>
                            </div>
                            <div className="form-group">
                                <Input type="password" id="password2" name="passwordRepeat"
                                       inputValue={this.state.controls.passwordRepeat.value}
                                       handleChange={this.handleChange}
                                       placeholder={t("user_settings.confirm_password")}
                                       errorMessage={!this.state.controls.passwordRepeat.valid && this.state.controls.passwordRepeat.touched ? t("form_validation.repeat_password") : ''}/>
                                <i className="icon_lock_alt"/>
                            </div>

                            <div id="pass-info" className="clearfix"/>
                            {this.props.loading ? <Spinner/> :
                                submitButton
                            }
                            <div className="text-center add_top_10">{t("company_registration.already_have_account_connected_company")} <strong><Link
                                to="/company-login">{t("home.sign_in")}</Link></strong></div>
                        </form>
                        <div className="copy">© {new Date().getFullYear()} Rezensa</div>
                    </aside>
                </div>
            </div>;

        return this.state.successful ? redirect : restartPasswordScreen;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onNotify: (message, type) => dispatch(actions.handleNotification(message, type)),
    };
};

export default connect(null, mapDispatchToProps)(withTranslation('common')(RestartPasswordComponent));
