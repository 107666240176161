import React, { useEffect, useState } from "react";
import RatingService from "../../services/rating.service";
import CompanyService from "../../services/company.service";
import * as RatingEnums from "../../constants/rating";
import { BENEFIT_TYPES_ICON_REPRESENTATIVE } from "../../constants/rating";
import Tooltip from "../layouts/Tooltip";
import { useParams } from 'react-router';
import { useHistory, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Spinner from "../layouts/Spinner";
import { getParsedDate } from '../../helpers/helper';


const SingleReview = (props) => {
    const [ratings, setRatings] = useState('');
    const [company, setCompany] = useState('');
    const history = useHistory();
    const { id } = useParams();
    const { t } = props;

    useEffect(() => {
        if (id) {
            RatingService.getRatingById(id)
                .then(review => {
                    setRatings(review.data)
                })
                .catch(error => {
                    history.push({
                        pathname: "/404"
                    });
                });
        }
    }, [history, id])

    useEffect(() => {
        if (ratings.length !== 0) {
            CompanyService.get(ratings[0].company)
                .then(company => {
                    setCompany(company.data);
                })
                .catch(error => {
                    history.push({
                        pathname: "/404"
                    });
                });
        }
    }, [history, ratings])

    return <section id="section-2" className="content-current" style={{ marginTop: '90px' }}>
        <div className="row justify-content-center">
            <div className="col-lg-8">
                {ratings.length !== 0 && company ? ratings.map(rating =>
                    <div className="review_card" key={rating._id}>
                        <div className="row">
                            <div className="col-md-3 user_info">
                                <figure><img src="../assets/img/avatar4.jpg" alt="" />
                                </figure>
                                <h5>{t("user_role_types." + rating.userRole)}</h5>
                                <h5>
                                    <em>{t("review.department")}: {t("user_department_types." + rating.userDepartment)}</em>
                                </h5>
                                <h5>
                                    <em>{t("company.company_name")}:  <Link to={"/company/" + company._id}>{company.name}</Link></em>
                                </h5>


                                <br />
                                <br />
                                <br />
                                {rating.recommendation ?
                                    <span> <i className="far fa-smile-beam fa-3x"
                                        style={{ color: "#32a067" }} /><br />{t("company.recommends")}</span>

                                    : <span> <i className="far fa-frown fa-3x"
                                        style={{ color: "#cc0000" }} /><br />{t("company.not_recommends")}</span>
                                }
                            </div>
                            <hr />
                            <div className="col-md-9 review_content">
                                <div className="clearfix add_bottom_15">
                                    {/*todo Export this to component */}
                                    {rating.averagePerDocument ?
                                        <div>
                                            <span
                                                className="rating"> {Array.from(Array(Math.round(company.average)), (_, i) =>
                                                    <i key={i}
                                                        className="icon_star" />)}
                                                {Array.from(Array(5 - Math.round(company.average)), (_, i) =>
                                                    <i
                                                        key={i}
                                                        className="icon_star empty" />)}
                                                <em>{Number((rating.averagePerDocument).toFixed(2))}/5.00</em>
                                            </span>

                                        </div>

                                        : ''}
                                    <em>{getParsedDate(rating.createdAt)}</em>

                                </div>
                                <h4>{rating.headline}</h4>
                            </div>

                        </div>
                        <br />
                        <div className="row">
                            {rating.likedStuffAboutCompany ?
                                <div className="col-12">
                                    <h5>{t("company.things_i_like")}:</h5>
                                    <p>
                                        {rating.likedStuffAboutCompany}
                                    </p>
                                </div>
                                : ''
                            }
                            {rating.dislikedStuffAboutCompany ?
                                <div className="col-12">
                                    <h5>{t("company.things_i_dont_like")}:</h5>
                                    <p>
                                        {rating.dislikedStuffAboutCompany}
                                    </p>
                                </div>
                                : ''
                            }
                            {rating.suggestionsForImprovement ?
                                <div className="col-12">
                                    <h5>{t("company.improvement_suggestions")}:</h5>
                                    <p>
                                        {rating.suggestionsForImprovement}
                                    </p>
                                </div>
                                : ''
                            }
                        </div>


                        <h5>{t("search.rating")}</h5>
                        {Object.values(RatingEnums.RATING_OPTIONS).map((item, ratingOptionIndex) =>
                            <div key={ratingOptionIndex} className="row"
                                style={{ marginBottom: "5px" }}>
                                <div className="col-6">
                                    <label>{t("rating_options." + item)}</label>
                                </div>
                                <div className="col-6">
                                    <span
                                        className="rating">
                                        {Array.from(Array(Math.round(rating[item])), (_, i) =>
                                            <i key={i}
                                                className="icon_star" />)}
                                        {Array.from(Array(5 - Math.round(rating[item])), (_, i) =>
                                            <i
                                                key={i}
                                                className="icon_star empty" />)}
                                        <em>{(Math.round(rating[item] * 100) / 100).toFixed(1)}</em>
                                    </span>
                                </div>
                            </div>
                        )}
                        <br />

                        {rating.length > 0 ? <div className="row">
                            <h4>{t("company.following_benefits_offered")}</h4>
                            <div className="col-md-12 text-center">
                                {rating.benefits.map((benefit, index) =>
                                    <Tooltip key={index}
                                        content={t("benefit_types." + benefit)}
                                        direction="top">
                                        <span style={{
                                            color: "#eef3f7",
                                            background: "#FFC107",
                                            borderRadius: "3px",
                                            padding: "5px",
                                            marginBottom: "0.4rem",
                                            marginRight: "0.2rem"
                                        }}
                                            className={BENEFIT_TYPES_ICON_REPRESENTATIVE[benefit] + " fa-3x"}>{/*&nbsp;*/}</span>
                                    </Tooltip>
                                )}
                            </div>
                        </div> :
                            <div>
                                <h4>{t("company.user_selected_no_benefits")}</h4>
                            </div>
                        }

                    </div>
                ) : <Spinner size={200} />}
            </div>
        </div>
    </section>
};

export default (withTranslation('common')(SingleReview));
