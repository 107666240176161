import React from 'react';
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';
import {withTranslation} from "react-i18next";

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

function StyledDropzone(props) {
    const maxSize = 5242880;

    const onDropAccepted = props.handleDrop;
    const t = props.t;

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDropAccepted,
        accept: ['image/png', 'image/jpeg', 'image/jpg'],
        maxSize: maxSize,
        minSize: 0,
        multiple: false,
        maxFiles: 1
    });


    return (
        <div className="container" style={{cursor:"pointer"}}>
            <Container {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
                <input {...getInputProps()} />
                <i className="fa fa-upload fa-3x"/>
                <p className="wb-type-hint-strong">{t("user_settings.dropzone_msg1")}</p>
                <span className="wb-type-button-secondary">{t("user_settings.dropzone_msg2")}</span>
            </Container>
        </div>
    );
}

export default withTranslation("common")(StyledDropzone);
