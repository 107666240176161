import React, {Component} from "react";
import {withRouter} from 'react-router-dom';
import * as RatingEnums from "../constants/rating";
import {withTranslation} from "react-i18next";
import * as CompanyEnums from "../constants/company";
import * as actions from "../store/actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Spinner from "./layouts/Spinner";
import queryString from 'query-string';

const SORT_BY = {
    HIGH_LOW: "high_low",
    LOW_HIGH: "low_high",
};

/*TODO kada se selectuje vise od jedne */
class SearchComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyName: "",
            industry: "",
            sortBy: SORT_BY.HIGH_LOW,
            country: "",
            city: "",
            rating_filtering: '0',
            benefits: [],
            searchString: "",
            limit: 10,
            searchInput: "",
            filteredResults: [],
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevState.companyName !== this.state.companyName) || (prevState.industry !== this.state.industry) || (prevState.sortBy !== this.state.sortBy) || (prevState.country !== this.state.country)
            || (prevState.city !== this.state.city) || (prevState.rating_filtering !== this.state.rating_filtering) || (prevState.benefits !== this.state.benefits)) {
            this.handleSubmit()
        }
    }

    resetFilters = () => {
        const newState = {
            companyName: "",
            industry: "",
            sortBy: SORT_BY.HIGH_LOW,
            country: "",
            city: "",
            rating_filtering: '0',
            benefits: [],
            searchString: "",
            searchInput: ""
        }
        this.setState(newState)
        this.handleSubmit();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            filteredResults: nextProps.companies,
        }, () => {
            let params = queryString.parse(this.props.location.search)
            if (JSON.stringify(params) !== '{}') {
                let categ;
                let search;
                if (Object.keys(params)[0] === "category") {
                    categ = params.category.toLowerCase().replace(/\s/g, '_');
                    this.setState({industry: categ}, () => {
                        this.handleSubmit();
                    })
                }
                if (Object.keys(params)[0] === "companyName") {
                    search = params.companyName.toLowerCase()
                    this.setState({companyName: search}, () => {
                        this.handleSubmit();
                    })
                }
            }
        });
    }

    componentDidMount() {
        const {location} = this.props.history;
        let companyName = new URLSearchParams(location.search).get("companyName");

        this.setState(
            {
                ...this.state,
                searchString:
                    "sortBy=" + this.state.sortBy + `&companyName=${companyName}`
            },
            () => {
                this.props.searchCompanies(this.state.searchString);
            }
        );
    }

    handleBenefitChange = (e) => {
        const currentIndex = this.state.benefits.indexOf(e.target.value);
        const temp = [...this.state.benefits];

        if (currentIndex === -1) {
            temp.push(e.target.value);
        } else {
            temp.splice(currentIndex, 1);
        }

        this.setState({benefits: temp});
    };

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    handleSubmit = () => {
        const {
            companyName,
            industry,
            country,
            city,
            rating_filtering,
            benefits,
            sortBy,
        } = this.state;

        const filtered = this.props.companies.filter((item) => {
            let num = item.average.toString().slice(0, 1);
            return (
                item.name?.toLowerCase().includes(companyName?.toLowerCase()) &&
                item.industryType?.toLowerCase().includes(industry?.toLowerCase()) &&
                item.country?.toLowerCase().includes(country?.toLowerCase()) &&
                item.city?.toLowerCase().includes(city?.toLowerCase()) &&
                num >= rating_filtering
            );
        });

        let benefitFilter = [];

        if (benefits.length !== 0) {
            benefitFilter = filtered.filter(a => a.benefits.some(a => benefits.includes(a)))
        }
        if (sortBy === SORT_BY.LOW_HIGH) {
            filtered.sort((a, b) => a.average - b.average)
        } else if (sortBy === SORT_BY.HIGH_LOW) {
            filtered.sort((a, b) => b.average - a.average)
        }

        if (benefits.length !== 0) {
            this.setState({filteredResults: benefitFilter})
        } else {
            this.setState({filteredResults: filtered})
        }
    };

    // handleChange = (e) => {
    //     let updatedControls;
    //     if (e.target.name !== 'benefits') {
    //         updatedControls = {
    //             ...this.state.controls,
    //             [e.target.name]: {
    //                 ...this.state.controls[e.target.name],
    //                 value: e.target.value,
    //                 //valid: this.checkValidity(e.target.value, this.state.controls[e.target.name].validation),
    //             }
    //         };
    //     } else {
    //         const isChecked = e.target.checked;
    //         if (!isChecked) {
    //             updatedControls = {
    //                 ...this.state.controls,
    //                 benefits: this.state.controls.benefits.filter((item) => item.value !== e.target.value)
    //             }
    //         } else {
    //             updatedControls = {
    //                 ...this.state.controls,
    //                 benefits: this.state.controls.benefits.concat({ value: e.target.value, required: false })
    //             }
    //         }
    //     }

    //     this.setState({ controls: updatedControls });
    // }

    handleLoadMore = () => {
        this.setState({limit: this.state.limit + 10});
    };

    render() {
        const {t} = this.props;
        let companies = this.props.loading ? (
            <Spinner size={200}/>
        ) : (
            this.state.filteredResults
                .slice(0, this.state.limit)
                .map((company) => (
                    <div key={company._id} className="col-md-6 isotope-item latest">
                        <div className="review_listing">
                            <div className="clearfix add_bottom_15">
                                <Link to={"/company/" + company._id}>
                                    <img
                                        style={{height: 267}}
                                        className="img-fluid"
                                        width={400}
                                        height={267}
                                        src={process.env.REACT_APP_BACKEND_URL + company.companyLogo}
                                        alt=""
                                    />
                                </Link>
                                <br/>
                                <br/>
                            </div>
                            <h4>
                                <Link to={"/company/" + company._id}>{company.name}</Link>
                            </h4>
                            <label>
                                <small>
                                    <i className="icon-location-8"/>
                                    {company.city}, {company.country}
                                </small>
                            </label>
                            <ul className="clearfix">
                                <li>
                                    <small>
                                        <i className="icon-tag"/>{" "}
                                        {t("industry_types." + company.industryType)}
                                    </small>
                                </li>
                                <li>
                                    {" "}
                                    <span className="rating">
                      {" "}
                                        {Array.from(Array(Math.round(company.average)), (_, i) => (
                                            <i key={i} className="icon_star"/>
                                        ))}
                                        {Array.from(
                                            Array(5 - Math.round(company.average)),
                                            (_, i) => (
                                                <i key={i} className="icon_star empty"/>
                                            )
                                        )}
                                        <em>
                        {(Math.round(company.average * 100) / 100).toFixed(1)}
                      </em>
                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                ))
        );

        const companyIndustries = (
            <select
                onChange={this.handleChange}
                className="form-control wide"
                name="industry"
                defaultValue={""}
            >
                <option value={""} disabled="disabled">
                    {t("review.industry")}
                </option>
                <option value={""}>
                    {"--- " + t("review.see_all") + " ---"}
                </option>
                {Object.values(CompanyEnums.COMPANY_INDUSTRIES).map((item, index) => (
                    this.state.industry && this.state.industry === item ? <option key={index} value={item} selected>
                            {t("industry_types." + item)}
                        </option> :
                        <option key={index} value={item}>
                            {t("industry_types." + item)}
                        </option>
                ))}
            </select>
        );

        const countries = (
            <select
                name="country"
                onChange={this.handleChange}
                className=" form-control wide"
                value={this.state.country}
            >
                <option value={""} disabled>
                    {t("search.country")}
                </option>
                {Object.values(CompanyEnums.COUNTRIES).map((item, index) => (
                    <option key={index} value={item}>
                        {t("country_two_iso_code." + item)}
                    </option>
                ))}
            </select>
        );

        return (
            <main>
                <div id="results">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-lg-2 col-md-4 col-10">
                                {this.state.filteredResults.length !== 1 ? <h1>
                                    <strong>{this.state.filteredResults.length}</strong> {t("search.results")}
                                </h1> : <h1>
                                    <strong>{this.state.filteredResults.length}</strong> {t("search.result")}
                                </h1>}
                            </div>
                            <div className="col-xl-7 col-md-6 col-2">
                                <a href="#0" className="search_mob btn_search_mobile">
                                    {" "}
                                </a>
                                <div className="row no-gutters custom-search-input-2 inner">
                                    <div className="col-lg-11">
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                onChange={this.handleChange}
                                                type="text"
                                                name="companyName"
                                                value={this.state.companyName}
                                                placeholder={t("search.search_company")}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-1 col-lg-1">
                                        <input
                                            type="submit"
                                            onClick={this.handleSubmit}
                                            value="Search"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="search_mob_wp">
                            <div className="custom-search-input-2">
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="companyName"
                                        type="text"
                                        placeholder={t("search.search_company")}
                                    />
                                    <i className="icon_search"/>
                                </div>
                                {companyIndustries}
                                <input type="submit" value={t("home.search")}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container margin_60_35">
                    <div className="row">
                        <aside className="col-lg-3" id="sidebar">
                            <div id="filters_col">
                                <a
                                    data-toggle="collapse"
                                    href="#collapseFilters"
                                    aria-expanded="false"
                                    aria-controls="collapseFilters"
                                    id="filters_col_bt"
                                >
                                    {t("search.filters")}{" "}
                                </a>
                                <div className="collapse show" id="collapseFilters">
                                    <button className="btn_top company" style={{width: "100%", marginTop: "10px"}}
                                            onClick={this.resetFilters}>{t("search.removeFilter")}</button>
                                    <div className="filter_type date_filter">
                                        <h6>{t("search.sort_by")}</h6>
                                        <ul>
                                            <li>
                                                <label className="container_radio">
                                                    {t("search.high_to_low")}
                                                    <input
                                                        type="radio"
                                                        name="sortBy"
                                                        value={SORT_BY.HIGH_LOW}
                                                        data-filter="*"
                                                        className="selected"
                                                        checked={this.state.sortBy === SORT_BY.HIGH_LOW}
                                                        onChange={this.handleChange}
                                                    />
                                                    <span className="checkmark"/>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="container_radio">
                                                    {t("search.low_to_high")}
                                                    <input
                                                        type="radio"
                                                        name="sortBy"
                                                        value={SORT_BY.LOW_HIGH}
                                                        data-filter=".latest"
                                                        checked={this.state.sortBy === SORT_BY.LOW_HIGH}
                                                        onChange={this.handleChange}
                                                    />
                                                    <span className="checkmark"/>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="filter_type">
                                        <h6>{t("review.industry")}</h6>
                                        <div className="form-group">{companyIndustries}</div>
                                    </div>
                                    <div className="filter_type">
                                        <h6>{t("search.location")}</h6>
                                        <div className="form-group">{countries}</div>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                onChange={this.handleChange}
                                                value={this.state.city}
                                                type="text"
                                                name="city"
                                                placeholder={t("review.city")}
                                            />
                                        </div>
                                    </div>
                                    <div className="filter_type">
                                        <h6>{t("search.benefits")}</h6>
                                        <ul>
                                            {Object.values(RatingEnums.BENEFIT_TYPES).map(
                                                (item, index) => (
                                                    <li key={index}>
                                                        <label className="container_check">
                                                            {t("benefit_types." + item)}
                                                            <input
                                                                type="checkbox"
                                                                value={item}
                                                                name="benefits"
                                                                onChange={this.handleBenefitChange}
                                                                checked={this.state.benefits.includes(item)}
                                                            />
                                                            <span className="checkmark"/>
                                                        </label>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                    <div className="filter_type">
                                        <h6>{t("search.rating")}</h6>
                                        <ul>
                                            <li>
                                                <label className="container_radio">
                                                    {t("search.all")}
                                                    <input
                                                        type="radio"
                                                        name="rating_filtering"
                                                        value="0"
                                                        onChange={this.handleChange}
                                                        checked={this.state.rating_filtering === '0'}
                                                    />
                                                    <span className="checkmark"/>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="container_radio">
                                                    {t("search.five_stars")}
                                                    <input
                                                        type="radio"
                                                        name="rating_filtering"
                                                        value="5"
                                                        onChange={this.handleChange}
                                                        checked={this.state.rating_filtering === '5'}
                                                    />
                                                    <span className="checkmark"/>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="container_radio">
                                                    {t("search.four_stars")}
                                                    <input
                                                        type="radio"
                                                        name="rating_filtering"
                                                        value="4"
                                                        onChange={this.handleChange}
                                                        checked={this.state.rating_filtering === '4'}
                                                    />
                                                    <span className="checkmark"/>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="container_radio">
                                                    {t("search.three_stars")}
                                                    <input
                                                        type="radio"
                                                        name="rating_filtering"
                                                        value="3"
                                                        onChange={this.handleChange}
                                                        checked={this.state.rating_filtering === '3'}
                                                    />
                                                    <span className="checkmark"/>
                                                </label>
                                            </li>
                                            <li>
                                                <label className="container_radio">
                                                    {t("search.two_stars")}
                                                    <input
                                                        type="radio"
                                                        name="rating_filtering"
                                                        value="2"
                                                        onChange={this.handleChange}
                                                        checked={this.state.rating_filtering === '2'}
                                                    />
                                                    <span className="checkmark"/>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </aside>
                        <div className="col-lg-9">
                            <div className="isotope-wrapper">
                                {this.state.filteredResults.length !== 0 ? <div className="row">{companies}</div> :
                                    <h4>  {t("company.no_results")}</h4>}
                            </div>

                            <p className="text-center">
                                {this.state.filteredResults.length !== 0 && this.state.filteredResults.length > this.state.limit && (
                                    <button
                                        onClick={this.handleLoadMore}
                                        className="btn_1 rounded add_top_15"
                                    >
                                        {t("search.load_more")}
                                    </button>
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchCompanies: (searchString, loadMore) =>
            dispatch(actions.searchCompanies(searchString, loadMore)),
    };
};

const mapStateToProps = (state) => {
    return {
        companies: state.searchedCompanies.companies,
        totalCount: state.searchedCompanies.totalCount,
        loading: state.searchedCompanies.loading,
        newCompaniesNumber: state.searchedCompanies.newCompaniesNumber,
    };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("common")(SearchComponent)));
