import http from "../../config/http-common";
import * as actionTypes from "./actionTypes";

export const getCompaniesStart = () => {
    return {
        type: actionTypes.COMPANIES_GET_START
    }
}
export const getCompaniesSuccessful = (data, loadMore) => {
    return {
        type: actionTypes.COMPANIES_GET,
        companies: data.companies,
        totalCount: data.totalCount[0].count,
        loadMore: loadMore
    }
}
export const getCompaniesFail = (error) => {
    return {
        type: actionTypes.COMPANIES_GET_FAIL,
        error: error
    }
}

//todo finish this and implemenet take parameter
export const searchCompanies = (searchString, loadMore = false) => {
    return dispatch => {
        let url = '/company?' + searchString;
        dispatch(getCompaniesStart());
        http.get(url)
            .then(response => {
                dispatch(getCompaniesSuccessful(response.data, loadMore))
            })
            .catch(error => {
                dispatch(getCompaniesFail(error))
            })
    }
}
