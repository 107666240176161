import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import * as actions from '../../store/actions'
import Spinner from '../layouts/Spinner'
import {useTranslation} from 'react-i18next'
import {getParsedDate} from "../../helpers/helper";
import * as RatingEnums from "../../constants/rating";
import {BENEFIT_TYPES_ICON_REPRESENTATIVE} from "../../constants/rating";
import Tooltip from "../layouts/Tooltip";
import parse from "html-react-parser";
import CompanyService from "../../services/company.service";
import {Link} from "react-router-dom";

function UserProfileComponent() {

    const {token, loading, profile} = useSelector((state) => ({
        token: state.auth.token,
        loading: state.userProfile.loading,
        profile: state.userProfile.userInfo,
    }))
    const dispatch = useDispatch();
    //relevant if company profile
    const [state, setState] = useState({
        ratings: [],
        ratingsRequested: false,
        showTabOne: true
    })


    useEffect(() => {
        dispatch(actions.getUserInfo(token))
    }, [])

    const switchTabs = () => {
        setState({
            ...state,
            showTabOne: !state.showTabOne
        })
    }
    useEffect(() => {
        if (!state.showTabOne && state.ratingsRequested === false) {
            CompanyService.getRatingsForCompany(profile.company._id)
                .then((ratings) => {
                    setState({
                        ...state,
                        ratings: ratings.data,
                        ratingsRequested: true,
                    })
                })
                .catch((error) => {
                    dispatch(actions.handleNotification(error.response.data, 'ERROR'))

                })
        }

    }, [state.showTabOne, state.ratingsRequested])

    //user
    const {username, ratings, verified} = {...profile}
    const {t} = useTranslation("common");

    const companyProfileSection =
        <div className="container margin_tabs">
            <div id="tabs" className="tabs">
                <nav>
                    <ul>
                        <li
                            onClick={switchTabs}
                            className={
                                state.showTabOne
                                    ? 'tab-current'
                                    : ''
                            }
                        >
                            <a href="#0">
                                <i className="pe-7s-note2"/>
                                {t('company.overview')}
                                <em>{t('company.msg1')}</em>
                            </a>
                        </li>
                        <li
                            onClick={switchTabs}
                            className={
                                !state.showTabOne
                                    ? 'tab-current'
                                    : ''
                            }
                        >
                            <a href="#0">
                                <i className="pe-7s-star"/>
                                {t('company.ratings')}
                                <em>{t('company.msg2')}</em>
                            </a>
                        </li>
                    </ul>
                </nav>
                <div className="content">
                    <section
                        id="section-1"
                        className={state.showTabOne ? 'content-current' : ''}>
                        <div className="row justify-content-center">

                            <div className="col-lg-8">
                                {profile.role === 'COMPANY' ?
                                    <h2>{profile.company.title}</h2> : ''}
                                {profile.role === 'COMPANY' ?
                                    profile.company.description ? (
                                        <div className="jumbotron jumbotron-fluid" style={{background: '#fff'}}>
                                            <div className="container">
                                                {parse(profile.company.description)}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="jumbotron jumbotron-fluid" style={{background: '#fff'}}>
                                            <div className="container">
                                                <h1 className="display-4">
                                                    {t('company.company_description_not_present')}
                                                </h1>
                                                <p className="lead">
                                                    <Link to={'/settings'} className="lead">
                                                        {t('profile.add_company_description')}
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    ) : ''}
                            </div>
                        </div>
                    </section>

                    <section
                        id="section-2"
                        className={
                            !state.showTabOne
                                ? 'content-current'
                                : ''
                        }
                    >
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                {state.ratings.length !== 0 && state.ratings ? state.ratings
                                    .slice(0, state.limit)
                                    .map((rating) => (
                                        <div
                                            className="review_card"
                                            key={rating._id}>
                                            <div className="row">
                                                <div className="col-md-3 user_info">
                                                    <figure>
                                                        <img src="../../assets/img/avatar4.jpg" alt=""/>
                                                    </figure>
                                                    <h5>
                                                        {t('user_role_types.' + rating.userRole)}
                                                    </h5>
                                                    <h5>
                                                        <em>
                                                            {t('review.department')}
                                                            : {' '} {t('user_department_types.' + rating.userDepartment)}
                                                        </em>
                                                    </h5>

                                                    <br/>
                                                    <br/>
                                                    <br/>
                                                    {rating.recommendation ? (
                                                        <span>
                                                                    {' '}
                                                            <i className="far fa-smile-beam fa-3x"
                                                               style={{color: '#32a067'}}/>
                                                                    <br/>
                                                            {t('company.recommends')}
                                                                </span>
                                                    ) : (
                                                        <span> {' '}
                                                            <i className="far fa-frown fa-3x"
                                                               style={{color: '#cc0000',}}/>
                                                                        <br/>
                                                            {t('company.not_recommends')}
                                                                    </span>
                                                    )}
                                                </div>
                                                <hr/>
                                                <div className="col-md-9 review_content">
                                                    <div className="clearfix add_bottom_15">
                                                        {/*todo Export this to component */}
                                                        {rating.averagePerDocument ? (
                                                            <div>
                                                                        <span className="rating">
                                                                            {' '}
                                                                            {Array.from(
                                                                                Array(Math.round(profile.average)),
                                                                                (_, i) => (
                                                                                    <i key={i} className="icon_star"/>
                                                                                )
                                                                            )}
                                                                            {Array.from(
                                                                                Array(5 - Math.round(profile.average)),
                                                                                (_, i) => (
                                                                                    <i key={i}
                                                                                       className="icon_star empty"/>
                                                                                )
                                                                            )}
                                                                            <em>
                                                                                {Number(rating.averagePerDocument.toFixed(2))}
                                                                                /5.00
                                                                            </em>
                                                                        </span>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                        <em>
                                                            {getParsedDate(rating.createdAt)}
                                                        </em>
                                                    </div>
                                                    <h4>
                                                        {
                                                            rating.headline
                                                        }
                                                    </h4>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="row">
                                                {rating.likedStuffAboutCompany ? (
                                                    <div className="col-12">
                                                        <h5>
                                                            {t(
                                                                'company.things_i_like'
                                                            )}
                                                            :
                                                        </h5>
                                                        <p>
                                                            {
                                                                rating.likedStuffAboutCompany
                                                            }
                                                        </p>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                                {rating.dislikedStuffAboutCompany ? (
                                                    <div className="col-12">
                                                        <h5>
                                                            {t(
                                                                'company.things_i_dont_like'
                                                            )}
                                                            :
                                                        </h5>
                                                        <p>
                                                            {
                                                                rating.dislikedStuffAboutCompany
                                                            }
                                                        </p>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                                {rating.suggestionsForImprovement ? (
                                                    <div className="col-12">
                                                        <h5>
                                                            {t(
                                                                'company.improvement_suggestions'
                                                            )}
                                                            :
                                                        </h5>
                                                        <p>
                                                            {
                                                                rating.suggestionsForImprovement
                                                            }
                                                        </p>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>

                                            <h5>
                                                {t('search.rating')}
                                            </h5>
                                            {Object.values(
                                                RatingEnums.RATING_OPTIONS
                                            ).map(
                                                (
                                                    item,
                                                    ratingOptionIndex
                                                ) => (
                                                    <div
                                                        key={
                                                            ratingOptionIndex
                                                        }
                                                        className="row"
                                                        style={{
                                                            marginBottom:
                                                                '5px',
                                                        }}
                                                    >
                                                        <div className="col-6">
                                                            <label>
                                                                {t(
                                                                    'rating_options.' +
                                                                    item
                                                                )}
                                                            </label>
                                                        </div>
                                                        <div className="col-6">
                                                                    <span className="rating">
                                                                        {Array.from(
                                                                            Array(Math.round(rating[item])),
                                                                            (_, i) => (
                                                                                <i key={i} className="icon_star"/>)
                                                                        )}
                                                                        {Array.from(
                                                                            Array(5 - Math.round(rating[item])),
                                                                            (_, i) => (
                                                                                <i key={i} className="icon_star empty"/>
                                                                            )
                                                                        )}
                                                                        <em>
                                                                            {(Math.round(rating[item] * 100) / 100).toFixed(1)}
                                                                        </em>
                                                                    </span>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                            <br/>

                                            {rating.benefits.length >
                                            0 ? (
                                                <div className="row">
                                                    <h4>
                                                        {t('company.following_benefits_offered')}
                                                    </h4>
                                                    <div className="col-md-12 text-center">
                                                        {rating.benefits.map(
                                                            (benefit, index) => (
                                                                <Tooltip key={index}
                                                                         content={t('benefit_types.' + benefit)}
                                                                         direction="top">
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            '#eef3f7',
                                                                                        background:
                                                                                            '#FFC107',
                                                                                        borderRadius:
                                                                                            '3px',
                                                                                        padding:
                                                                                            '5px',
                                                                                        marginBottom:
                                                                                            '0.4rem',
                                                                                        marginRight:
                                                                                            '0.2rem',
                                                                                    }}
                                                                                    className={BENEFIT_TYPES_ICON_REPRESENTATIVE[benefit] + ' fa-3x'}>
                                                                                    {/*&nbsp;*/}
                                                                                </span>
                                                                </Tooltip>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <h4>
                                                        {t('company.user_selected_no_benefits')}
                                                    </h4>
                                                </div>
                                            )}
                                        </div>
                                    )) : <h6>{t('company.no_results')}</h6>}

                                {state.ratings.length !== 0 && state.ratings.length > state.limit &&
                                    <div className="pagination__wrapper add_bottom_15">
                                        <p className="text-center">
                                            <button
                                               /* onClick={handleLoadMore}*/
                                                className="btn_1 rounded add_top_15">
                                                {t('search.load_more')}
                                            </button>
                                        </p>
                                    </div>}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    ;

    const userProfileSection =
        <div className="container margin_60_35">
            <div className="row">
                <div className="col-lg-12">

                    {ratings && ratings.length > 0 ? ratings?.map((rating) => (
                            <div className="review_card"
                                 key={rating._id}>
                                <div className="row">
                                    <div className="col-md-2 user_info">
                                        <figure>
                                            <img
                                                src="../../assets/img/avatar4.jpg"
                                                alt=""
                                            />
                                        </figure>
                                        <h5>
                                            Review: "Good
                                            Electronics"
                                        </h5>
                                    </div>
                                    <div className="col-md-10 review_content">
                                        <div className="clearfix add_bottom_15">
                                                            <span className="rating">
                                                                <i className="icon_star"/>
                                                                <i className="icon_star"/>
                                                                <i className="icon_star"/>
                                                                <i className="icon_star"/>
                                                                <i className="icon_star"/>
                                                                <em>5.00/5.00</em>
                                                            </span>
                                            <em>
                                                {t("home.published")}:{' '}
                                                {moment(
                                                    rating?.createdAt
                                                ).fromNow()}
                                            </em>
                                        </div>
                                        <h4>{rating?.headline}</h4>
                                        <p>
                                            {
                                                rating?.suggestionsForImprovement
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )) :
                        <div className="review_card">
                            <div className="row">
                                <h4>{t("profile.no_ratings")}</h4>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>

    return (
        <>
            {loading ? (<Spinner/>) : (
                <main className="margin_main_container">
                    <div className="user_summary">
                        <div className="wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <figure>
                                            <img src="../../assets/img/avatar4.jpg" alt=""/>
                                        </figure>
                                        {profile.role === 'USER' || profile.role === 'ADMIN' ?
                                            <h1>{username}</h1> :
                                            <h1>{profile && profile.firstName + ' ' + profile.lastName}</h1>}
                                        <h6>
                                            {profile.role === 'USER'
                                                ? verified ? t('profile.email_verified') : t('profile.email_unverified')
                                                : profile.role === 'COMPANY' ? profile.company.name : ''}
                                        </h6>
                                    </div>
                                    <div className="col-md-6">
                                        <ul>
                                            <li>
                                                {profile.role === 'USER' || profile.role === 'ADMIN' ?
                                                    <strong>
                                                        {ratings?.length}
                                                    </strong> : <strong>
                                                        {profile.company && profile.ratings.length}
                                                    </strong>}
                                                <a href="#0"
                                                   className="tooltips"
                                                   data-toggle="tooltip"
                                                   data-placement="bottom">
                                                    <i className="icon_star"/>{' '}
                                                    {t("search.rating")}
                                                </a>
                                            </li>
                                            {profile.role === 'COMPANY' ?
                                                <li>
                                                    <strong>{Math.round(profile.average * 10) / 10}</strong>
                                                    <a href="#0"
                                                       className="tooltips"
                                                       data-toggle="tooltip"
                                                       data-placement="bottom">
                                                        <i className="icon-user-1"/>
                                                        {t("profile.company_rating_mark")}
                                                    </a>
                                                </li> : ''
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {!profile.company ? userProfileSection :
                        <div>
                            <br/> <br/>
                            {companyProfileSection}
                        </div>
                    }

                </main>
            )}
        </>
    )
}


export default UserProfileComponent;
