import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../store/actions";
import Spinner from "./Spinner";
import Modal from "react-modal";
import Input from "./Input";
import {checkValidity} from "../../shared/validation";
import http from "../../config/http-common";
import './SignInPopUp.css'

Modal.setAppElement("#root");


const initialState = {
    isOpen: false,
    setIsOpen: false,
    forgotPwShow: false,
    controls: {
        email: {
            value: '',
            touched: false,
            valid: false,
            validation: {
                isEmail: true,
                required: true
            }
        },
        password: {
            value: '',
            valid: false,
            touched: false,
            validation: {
                required: true,
                minLength: 7
            }
        },
        rememberMe: {
            value: false,
            validation: {
                required: true,
                minLength: 7
            }
        },
        email_forgot: {
            value: '',
            touched: false,
            valid: false,
            validation: {
                isEmail: true,
                required: true
            }
        }
    }
};

function SignInPopUp(props) {
    const {t} = useTranslation('common');
    const [state, setState] = useState(initialState);
    const {isAuth, loading} = useSelector((state) => ({
        isAuth: state.auth.token !== null,
        loading: state.auth.loading
    }))

    const dispatch = useDispatch()

    const handleChange = (e) => {
        const updatedControls = {
            ...state.controls,
            [e.target.name]: {
                ...state.controls[e.target.name],
                value: e.target.value,
                valid: checkValidity(e.target.value, state.controls[e.target.name].validation),
                touched: true
            }
        };
        setState({
            ...state,
            controls: updatedControls
        });
    }

    const handleShowForgotPassword = () => {
        setState({
            ...state,
            forgotPwShow: !state.forgotPwShow
        })
    }

    const handleSubmit = () => {
        dispatch(actions.auth(state.controls.email.value, state.controls.password.value, state.controls.rememberMe.value))
    }

    useEffect(() => {
        if (isAuth && props.isOpen) {
            props.modalToggle();
            setState(initialState);
        }
    }, [isAuth, props.isOpen])

    const handleResetPasswordSubmit = () => {
        http.post("/restart-password", {email: state.controls.email_forgot.value})
            .then(data => {
                dispatch(actions.handleNotification(data.data.message, "INFO"))
                props.modalToggle();
                setState(initialState);
            })
            .catch(error => {
                dispatch(actions.handleNotification(error.response.data, "ERROR"))
            })
    }

    const [passwordType, setPasswordType] = useState("password");
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    return (props.isOpen
            ?
            <Modal
                className="mymodal"
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
                onRequestClose={props.modalToggle}
                isOpen={props.isOpen}
                shouldCloseOnOverlayClick={true}>
                <div id="sign-in-dialog">
                    <div className="small-dialog-header">
                        <h3>{t("home.sign_in")}</h3>
                    </div>
                    <div className="sign-in-wrapper">

                        {!state.forgotPwShow
                            ? <div>
                                <form>
                                    <div className="form-group">
                                        <label>{t("home.email")}</label>
                                        <Input type="email" name="email"
                                               id="email"
                                               inputValue={state.controls.email.value}
                                               handleChange={handleChange}
                                               errorMessage={!state.controls.email.valid && state.controls.email.touched ? t("form_validation.email") : ''}/>
                                        <i className="icon_mail_alt"/>
                                    </div>
                                    <div className="form-group">
                                        <label>{t("home.password")}</label>
                                        <Input type={passwordType} id="login_password" name="password"
                                               inputValue={state.controls.password.value}
                                               handleChange={handleChange}
                                               errorMessage={!state.controls.password.valid && state.controls.password.touched ? t("form_validation.password") : ''}/>
                                        {passwordType === "password" ?
                                            <i onClick={togglePassword}
                                               className="icon_lock_alt"/> :
                                            <i onClick={togglePassword}
                                               className="icon_lock-open_alt"/>}
                                    </div>
                                    <div className="clearfix add_bottom_15">
                                        <div className="checkboxes float-left">
                                            <label className="container_check">
                                                {t("home.remember_me")}
                                                <input name="rememberMe" value={state.controls.rememberMe.value}
                                                       onChange={handleChange}
                                                       type="checkbox"/>
                                                <span className="checkmark"/>
                                            </label>
                                        </div>
                                        <div className="float-right mt-1">
                                            <a href="#0" onClick={handleShowForgotPassword}
                                               id="forgot">{t("home.forgot_password")}</a>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        {loading ? <Spinner/> :
                                            <button className="btn_1 full-width"
                                                    onClick={handleSubmit}
                                                    id="signInPopUpButton">{t("home.sign_in")}</button>
                                        }
                                    </div>
                                    <div className="text-center">
                                        {t("home.dont_have_account")}
                                        <a href="/register" id="registerPopUpButton"> {t("home.sign_up")}</a>
                                    </div>
                                    <div id="pass-info" className="clearfix"/>
                                </form>
                            </div>
                            : <div>
                                <div className="form-group">
                                    <label>{t("home.email_confirm")}</label>
                                    <Input type="email" name="email_forgot"
                                           inputValue={state.controls.email_forgot.value}
                                           handleChange={handleChange}
                                           id="email_forgot"
                                           errorMessage={!state.controls.email_forgot.valid && state.controls.email_forgot.touched ? t("form_validation.email") : ''}/>
                                    <i className="icon_mail_alt"/>
                                </div>
                                <p>{t("home.reset_password_msg")}</p>
                                <div className="text-center">
                                    <button onClick={handleResetPasswordSubmit}
                                            className="btn_1">{t("home.reset_password")}</button>
                                </div>
                                <div className="text-center">
                                    <br/>
                                    <span onClick={handleShowForgotPassword} id="back"> {t("home.back")}</span>
                                </div>
                            </div>
                        }


                    </div>
                    <button onClick={props.modalToggle} type="button" className="mfp-close"/>
                </div>
            </Modal>
            : null
    )
}

export default SignInPopUp;
