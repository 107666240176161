import http from "../../config/http-common";
import * as actionTypes from "./actionTypes";

export const getReviewsStart = () => {
    return {
        type: actionTypes.REVIEWS_GET_START
    }
}

export const getReviewStart = () => {
    return {
        type: actionTypes.REVIEW_GET_START
    }
}

export const getReviewsSuccessful = (reviews) => {
    return {
        type: actionTypes.REVIEWS_GET,
        reviews: reviews
    }
}
export const getReviewSuccessful = (review) => {
    return {
        type: actionTypes.REVIEW_GET,
        review: review
    }
}

export const getReviewsFailed = (error) => {
    return {
        type: actionTypes.REVIEW_FAIL,
        error: error
    }
}
export const getReviewFailed = (error) => {
    return {
        type: actionTypes.REVIEW_FAIL,
        error: error
    }
}

export const reviews = (numberOfRequests) => {
    return dispatch => {
        let url = `/rating`;
        if (numberOfRequests) {
            url = url + `?number=` + numberOfRequests
        }
        dispatch(getReviewsStart());

        http.get(url)
            .then(response => {
                dispatch(getReviewsSuccessful(response.data))
            })
            .catch(error => {
                dispatch(getReviewsFailed(error));
            });
    }
}

export const review = (id) => {
    return dispatch => {
        let url = `/rating/`;
        if (id) {
            url = url + id
        }
        dispatch(getReviewStart());

        http.get(url)
            .then(response => {
                dispatch(getReviewSuccessful(response.data))
            })
            .catch(error => {
                dispatch(getReviewFailed(error));
            });
    }
}
