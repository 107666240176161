import Modal from "react-modal";
import React from "react";
import {withTranslation} from "react-i18next";

const UserProfileDeleteConfirm = props => {


    return (
        <Modal
            className="mymodal"
            overlayClassName="myoverlay"
            closeTimeoutMS={500}
            onRequestClose={props.modalToggle}
            isOpen={props.isOpen}
            shouldCloseOnOverlayClick={true}>
            <div id="sign-in-dialog">
                <div className="small-dialog-header">
                    <h3>{props.t("user_settings.delete_account")}</h3>
                </div>
                <div className="sign-in-wrapper">
                    <div>
                        <p>{props.t("user_settings.delete_account_question")}</p>
                        <div className="text-center">
                            <button className="btn_1_danger small" onClick={props.handleDelete}>
                                {props.t("review.yes")}
                            </button>
                        </div>
                        <div className="text-center">
                            <br/>
                            <span id="back" onClick={props.modalToggle}> {props.t("home.back")}</span>
                        </div>
                    </div>
                </div>
                <button onClick={props.modalToggle} type="button" className="mfp-close"/>
            </div>
        </Modal>
    )
}

export default (withTranslation("common")(UserProfileDeleteConfirm));
