import React, { useEffect, useState } from "react";
import ConfirmScreen from "./review/ConfirmScreen";
import http from "../config/http-common";
import { useDispatch } from "react-redux";
import * as actions from "../store/actions";

function ConfirmEmailComponent(props) {
  const [conformationSuccessful, setConformationSuccessful] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    http
      .get("/token-verify/" + props.match.params.token)
      .then((data) => {
        dispatch(actions.handleNotification(data.data.message, "INFO"));
        setConformationSuccessful(true);
      })
      .catch((error) => {
        dispatch(actions.handleNotification(error.response.data, "ERROR"));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {conformationSuccessful ? (
        <ConfirmScreen headerMessage={"home.email_confirmed"} subheaderMessage={"home.email_confirmed_msg"}/>
      ) : (
        <ConfirmScreen headerMessage={"home.email_confirmed_fail"} subheaderMessage={"home.email_confirmed_fail_msg"} successScreen={false}/>
      )}
    </>
  );
}

export default ConfirmEmailComponent;
