import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    companies: [],
    error: null,
    loading: false
};

export const getCompaniesStart = (state, action) => {
    return updateObject(state, {
        loading: true
    });
};

export const getCompaniesSuccess = (state, action) => {
    return updateObject(state, {
        companies: action.companies,
        loading: false
    });
};


export const getCompaniesFail = (state, action) => {
    return updateObject(state, {
        error: action.errors,
        loading: false
    });
};


export const getCompaniesDone = () => {
    return {
        loading: false,
        error: null,
        companies: []
    }
}



const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.COMPANY_GET_START:
            return getCompaniesStart(state, action);
        case actionTypes.COMPANY_GET_SUCCESS:
            return getCompaniesSuccess(state, action);
        case actionTypes.COMPANY_GET_FAIL:
            return getCompaniesFail(state, action);
        case actionTypes.COMPANY_GET_DONE:
            return getCompaniesDone();
        default:
            return state;
    }
};

export default reducer;
