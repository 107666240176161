import React from "react";
import "moment/locale/hr";
import { checkValidity } from "../../shared/validation";
import Input from "./Input";
import { withTranslation } from "react-i18next";
import SupportService from "../../services/support.service";
import * as actions from "../../store/actions";
import { connect } from "react-redux";

const initialStata = {
  controls: {
    questionTab: {
      firstName: {
        value: "",
        validation: {
          required: true,
          minLength: 2,
          maxLength: 500,
        },
        valid: false,
        touched: false,
      },
      lastName: {
        value: "",
        validation: {
          required: true,
          minLength: 2,
          maxLength: 500,
        },
        valid: false,
        touched: false,
      },
      email: {
        value: "",
        validation: {
          isEmail: true,
          required: true,
        },
        valid: false,
        touched: false,
      },
      phone: {
        value: "",
        validation: {
          required: true,
          isPhoneNumber: true,
        },
        valid: false,
        touched: false,
      },
      messageQuestion: {
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
    },
    supportTab: {
      nameSupport: {
        value: "",
        validation: {
          minLength: 2,
          maxLength: 500,
          required: true,
        },
        valid: false,
        touched: false,
      },
      emailSupport: {
        value: "",
        validation: {
          isEmail: true,
          required: true,
        },
        valid: false,
        touched: false,
      },
      messageSupport: {
        value: "",
        validation: {
          required: true,
          minLength: 2,
          maxLength: 500,
        },
        valid: false,
        touched: false,
      },
    },
  },
  formValid: false,
  showTabOne: true,
};
class HelpComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialStata;
  }

  handleChange = (e) => {
    let updatedControls;
    let formIsValid = true;
    if (this.state.showTabOne) {
      updatedControls = {
        ...this.state.controls,
        questionTab: {
          ...this.state.controls.questionTab,
          [e.target.name]: {
            ...this.state.controls.questionTab[e.target.name],
            value: e.target.value,
            valid: checkValidity(
              e.target.value,
              this.state.controls.questionTab[e.target.name].validation
            ),
            touched: true,
          },
        },
      };

      for (let inputIdentifier in updatedControls.questionTab) {
        formIsValid =
          updatedControls.questionTab[inputIdentifier].valid && formIsValid;
      }
    } else {
      updatedControls = {
        ...this.state.controls,
        supportTab: {
          ...this.state.controls.supportTab,
          [e.target.name]: {
            ...this.state.controls.supportTab[e.target.name],
            value: e.target.value,
            valid: checkValidity(
              e.target.value,
              this.state.controls.supportTab[e.target.name].validation
            ),
            touched: true,
          },
        },
      };

      for (let inputIdentifier in updatedControls.supportTab) {
        formIsValid =
          updatedControls.supportTab[inputIdentifier].valid && formIsValid;
      }
    }

    this.setState({ controls: updatedControls, formIsValid: formIsValid });
  };

  handleSubmit = () => {
    if (this.state.showTabOne) {
      const question = {
        firstName: this.state.controls.questionTab.firstName.value,
        lastName: this.state.controls.questionTab.lastName.value,
        email: this.state.controls.questionTab.email.value,
        message: this.state.controls.questionTab.messageQuestion.value,
      };
      if (this.state.controls.questionTab.phone.value !== "")
        question.phone = this.state.controls.questionTab.phone.value;

      SupportService.createQuestionTicket(question)
        .then((response) => {
          this.props.onNotify(response.data, "INFO");
          this.setState({ ...initialStata });
        })
        .catch((error) => {
          this.props.onNotify(error.response.data, "ERROR");
        });
    } else {
      const support = {
        name: this.state.controls.supportTab.nameSupport.value,
        email: this.state.controls.supportTab.emailSupport.value,
        message: this.state.controls.supportTab.messageSupport.value,
      };
      SupportService.createSupportTicket(support)
        .then((response) => {
          this.props.onNotify(response.data, "INFO");
          this.setState({ ...initialStata });
        })
        .catch((error) => {
          this.props.onNotify(error.response.data, "ERROR");
        });
    }
  };

  switchTabs = () => {
    this.setState({
      showTabOne: !this.state.showTabOne,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <main>
        <section className="hero_single general">
          <div className="wrapper">
            <div className="container">
              <h1> {t("help.msg1")}</h1>
              <p> {t("help.msg2")}</p>
            </div>
          </div>
        </section>

        <div className="bg_color_1">
          <div className="container margin_tabs">
            <div id="tabs" className="tabs">
              <nav>
                <ul>
                  <li
                    onClick={this.switchTabs}
                    className={this.state.showTabOne ? "tab-current" : ""}
                  >
                    <a href="#0">
                      <i className="pe-7s-help1" />
                      {t("help.questions")}
                      <em>{t("help.questions_message")}</em>
                    </a>
                  </li>
                  <li
                    onClick={this.switchTabs}
                    className={!this.state.showTabOne ? "tab-current" : ""}
                  >
                    <a href="#0">
                      <i className="pe-7s-help2" />
                      {t("help.support")}
                      <em>{t("help.support_message")}</em>
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="content">
                <section
                  id="section-1"
                  className={this.state.showTabOne ? "content-current" : ""}
                >
                  <div className="row justify-content-center">
                    <div className="col-lg-8">
                      <div id="message-contact" />
                      <div id="contactform">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group required">
                              <Input
                                type="text"
                                name="firstName"
                                id="firstName"
                                inputValue={
                                  this.state.controls.questionTab.firstName
                                    .value
                                }
                                handleChange={this.handleChange}
                                placeholder={t("help.first_name")}
                                errorMessage={
                                  !this.state.controls.questionTab.firstName
                                    .valid &&
                                  this.state.controls.questionTab.firstName
                                    .touched
                                    ? t("form_validation.first_name")
                                    : ""
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group required">
                              <Input
                                type="text"
                                name="lastName"
                                id="lastName"
                                inputValue={
                                  this.state.controls.questionTab.lastName.value
                                }
                                handleChange={this.handleChange}
                                placeholder={t("help.last_name")}
                                errorMessage={
                                  !this.state.controls.questionTab.lastName
                                    .valid &&
                                  this.state.controls.questionTab.lastName
                                    .touched
                                    ? t("form_validation.last_name")
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group required">
                              <Input
                                type="email"
                                name="email"
                                inputValue={
                                  this.state.controls.questionTab.email.value
                                }
                                handleChange={this.handleChange}
                                placeholder={t("home.email")}
                                errorMessage={
                                  !this.state.controls.questionTab.email
                                    .valid &&
                                  this.state.controls.questionTab.email.touched
                                    ? t("form_validation.email")
                                    : ""
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <Input
                                type="text"
                                name="phone"
                                id="phone"
                                inputValue={
                                  this.state.controls.questionTab.phone.value
                                }
                                handleChange={this.handleChange}
                                placeholder={t("help.phone")}
                                errorMessage={
                                  !this.state.controls.questionTab.phone
                                    .valid &&
                                  this.state.controls.questionTab.phone.touched
                                    ? t("form_validation.phone")
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group required">
                          <textarea
                            className="form-control"
                            id="messageQuestion"
                            name="messageQuestion"
                            style={{ height: "150px" }}
                            onChange={this.handleChange}
                            placeholder={t("help.message")}
                            value={this.state.controls.questionTab.messageQuestion.value}
                          />
                          <div className="text-danger">
                            {!this.state.controls.questionTab.messageQuestion
                              .valid &&
                            this.state.controls.questionTab.messageQuestion
                              .touched
                              ? t("form_validation.message")
                              : ""}
                          </div>
                        </div>
                        <div className="form-group add_top_30 text-center">
                          <button
                            className="btn_1 rounded"
                            id="submit-contact"
                            onClick={this.handleSubmit}
                          >
                            {t("help.submit")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section
                  id="section-2"
                  className={!this.state.showTabOne ? "content-current" : ""}
                >
                  <div className="row justify-content-center">
                    <div className="col-lg-8">
                      <div id="support">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group required">
                              <Input
                                type="text"
                                name="nameSupport"
                                inputValue={
                                  this.state.controls.supportTab.nameSupport
                                    .value
                                }
                                handleChange={this.handleChange}
                                placeholder={t("help.name")}
                                errorMessage={
                                  !this.state.controls.supportTab.nameSupport
                                    .valid &&
                                  this.state.controls.supportTab.nameSupport
                                    .touched
                                    ? t("form_validation.name")
                                    : ""
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group required">
                              <Input
                                type="email"
                                name="emailSupport"
                                inputValue={
                                  this.state.controls.supportTab.emailSupport
                                    .value
                                }
                                handleChange={this.handleChange}
                                placeholder={t("home.email")}
                                errorMessage={
                                  !this.state.controls.supportTab.emailSupport
                                    .valid &&
                                  this.state.controls.supportTab.emailSupport
                                    .touched
                                    ? t("form_validation.email")
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group required">
                          <textarea
                            className="form-control"
                            id="messageSupport"
                            onChange={this.handleChange}
                            name="messageSupport"
                            style={{ height: "150px" }}
                            placeholder={t("help.support_request")}
                            value={this.state.controls.supportTab.messageSupport.value}
                          />
                          <div className="text-danger">
                            {!this.state.controls.supportTab.messageSupport
                              .valid &&
                            this.state.controls.supportTab.messageSupport
                              .touched
                              ? t("form_validation.message")
                              : ""}
                          </div>
                        </div>
                        <div className="form-group add_top_30 text-center">
                          <button
                            className="btn_1 rounded"
                            id="submit-support"
                            onClick={this.handleSubmit}
                          >
                            {" "}
                            {t("help.submit")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div className="container margin_60_35">
          <div className="row">
            <div className="col-md-6">
              <div className="box_faq">
                <i className="icon_info_alt" />
                <h4>Porro soleat pri ex, at has lorem accusamus?</h4>
                <p>
                  Ut unum diceret eos, mel cu velit principes, ut quo inani
                  dolorem mediocritatem. Mea in justo posidonium necessitatibus.
                  Augue honestatis vis no, ius quot mazim forensibus in, per
                  sale virtute legimus ne. Mea dicta facilisis eu.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box_faq">
                <i className="icon_info_alt" />
                <h4>Ut quo inani dolorem mediocritatem?</h4>
                <p>
                  Ut unum diceret eos, mel cu velit principes, ut quo inani
                  dolorem mediocritatem. Mea in justo posidonium necessitatibus.
                  Augue honestatis vis no, ius quot mazim forensibus in, per
                  sale virtute legimus ne. Mea dicta facilisis eu.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="box_faq">
                <i className="icon_info_alt" />
                <h4>Per sale virtute legimus ne?</h4>
                <p>
                  Ut unum diceret eos, mel cu velit principes, ut quo inani
                  dolorem mediocritatem. Mea in justo posidonium necessitatibus.
                  Augue honestatis vis no, ius quot mazim forensibus in, per
                  sale virtute legimus ne. Mea dicta facilisis eu.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box_faq">
                <i className="icon_info_alt" />
                <h4>Mea in justo posidonium necessitatibus?</h4>
                <p>
                  Ut unum diceret eos, mel cu velit principes, ut quo inani
                  dolorem mediocritatem. Mea in justo posidonium necessitatibus.
                  Augue honestatis vis no, ius quot mazim forensibus in, per
                  sale virtute legimus ne. Mea dicta facilisis eu.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onNotify: (message, type) =>
      dispatch(actions.handleNotification(message, type)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withTranslation("common")(HelpComponent));
