import React, {useEffect, useRef, useState} from "react";
import CompanyService from "../../services/company.service";
import Autocomplete from "./Autocomplete";
import {useTranslation} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import CompanyCategoryHome from "./CompanyCategoryHome";
import RatingHome from "./RatingHome";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../store/actions";
import Spinner from "./Spinner";

function HomeComponent(props) {
    const { t } = useTranslation('common');
    const numberOfRequestedReviews = 5;
    const numberOfRequestedCategories = 6;
    const { companiesCategories, reviews, reviewsLoading, isAuth } = useSelector(state => ({
        companiesCategories: state.companiesCategories.categories,
        reviews: state.reviews.reviews,
        reviewsLoading: state.reviews.loading,
        isAuth: state.auth.token !== null
    }));
    const dispatch = useDispatch();
    const history = useHistory()

    let wrapperRef = useRef(null)
    const [state,setState] = useState({
        companyName: "",
        companies: [],
        autoCompleteMenuVisible: false,
        selectedCompany: {
            name: "",
            id: "",
        },
        loading: false,
    });


    const handleChange = async (e) => {
        let value = {
            ...state,
            companyName: e.target.value,
            selectedCompany: {
                name: "",
                id: ""
            },
        }

        if (e.target.value.length > 0) {
            try {
                const response = await CompanyService.findByName(e.target.value)
                value = {
                    ...value,
                    companies: response.data.map((item) => ({
                        name: item.name,
                        id: item._id,
                    })),
                    autoCompleteMenuVisible: true,
                }
            } catch (e) {
                console.log(e)
            }
        } else {
            value = {
                ...value,
                autoCompleteMenuVisible: false,
            }
        }
        setState(value)
    };

    const handleCompanySelect = (index) => {
        setState({
            ...state,
            selectedCompany: state.companies[index],
            companies: [],
            autoCompleteMenuVisible: false,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (state.selectedCompany.id) {
            history.push("/company/" + state.selectedCompany.id);
        } else {
            history.push("/search?companyName=" + state.companyName);
        }
    };

    useEffect(() => {
        dispatch(actions.categories(numberOfRequestedCategories));
        dispatch(actions.reviews(numberOfRequestedReviews));
    }, [])


    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [wrapperRef])


    const handleAutocompleteClick = () => {
        setState({
            ...state,
            autoCompleteMenuVisible: true,
        });
    };

    const handleClickOutside = (event) => {
        if (wrapperRef && !wrapperRef.current.contains(event.target)) {
            if (state.autoCompleteMenuVisible) {
                setState({
                    ...state,
                    autoCompleteMenuVisible: false,
                });
            }
        }
    }

    return (
        <main>
            <section className="hero_single version_1">
                <div className="wrapper">
                    <div className="container">
                        <h3>{t("home.headline")}</h3>
                        <p>{t("home.sub_headline")}</p>
                        <div className="row justify-content-center">
                            <div className="col-lg-9">
                                <form onSubmit={handleSubmit} ref={wrapperRef}>
                                    <div className="row no-gutters custom-search-input-2">
                                        <div className="col-lg-8">
                                            <Autocomplete
                                                onChangeFunction={handleChange}
                                                placeholder={t("home.type_company_name")}
                                                inputValue={
                                                    state.selectedCompany.name.length > 0
                                                        ? state.selectedCompany.name
                                                        : state.companyName
                                                }
                                                autoCompleteMenuVisible={
                                                    state.autoCompleteMenuVisible
                                                }
                                                data={state.companies}
                                                name={"company"}
                                                menuItemClicked={handleCompanySelect}
                                                click={handleAutocompleteClick}
                                                withSearchIcon={true}
                                            />
                                        </div>
                                        <div className="col-lg-4">
                                            <input
                                                type="submit"
                                                value={t("home.search")}
                                                disabled={
                                                    !(
                                                        state.selectedCompany.id ||
                                                        state.companyName
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h4 className="text-center" style={{color: "white"}}>
                                                {t("home.or")}
                                            </h4>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <Link to="/review" className="home-review-btn">
                                                {t("home.write_review")}
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container margin_60_35">
                <div className="main_title_3">
                    <h2>{t("home.categories")}</h2>
                    <Link to="/search">{t("home.view_all")}</Link>
                </div>
                <div className="row justify-content-center">
                    <CompanyCategoryHome
                        companiesCategories={companiesCategories}
                    />
                </div>
            </div>
            {reviews.length > 0 &&
                <div className="bg_color_1">
                    <div className="container margin_60">
                        <div className="main_title_3">
                            <h2>{t("home.latest_review")}</h2>
                        </div>
                        <div id="recommended_reviews">
                            {reviews ? (
                                <RatingHome
                                    reviews={reviews}
                                    reviewsLoading={reviewsLoading}
                                />
                            ) : (
                                <Spinner/>
                            )}
                        </div>
                    </div>
                </div>}
            <div className="call_section_3">
                <div className="wrapper">
                    <div className="container clearfix">
                        <div className="col-lg-5 col-md-7 float-right">
                            <h3>{t("home.help")}</h3>
                            <p>{t("home.help_desc")}</p>
                            {!isAuth && (
                                <p>
                                <a className="btn_1 add_top_10 wow bounceIn"
                                   href="/register"
                                   data-wow-delay="0.5s">
                                    {t("home.join")}
                                </a>
                            </p>)}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
export default HomeComponent;
