import React from "react";
import {RingLoader} from "react-spinners";


function Spinner(props) {
    const size = props.size ? props.size : 50;
    return (
        <div className="sweet-loading offset-5 ">
            <RingLoader
                size={size}
                color={"#FFC107"}
            />
        </div>
    )
}

export default Spinner;
