import React, {useState} from "react";
import {Link, Redirect} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../store/actions";
import Spinner from "./layouts/Spinner";
import {checkValidity} from "../shared/validation";
import Input from "./layouts/Input";

const initialState = {
    formIsValid: false,
    controls: {
        email: {
            value: "",
            validation: {
                isEmail: true,
                required: true
            },
            valid: false,
            touched: false
        },
        password: {
            value: "",
            validation: {
                required: true,
                minLength: 7
            },
            valid: false,
            touched: false
        },
        passwordRepeat: {
            value: "",
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        termsAndPrivacy: {
            value: false,
            validation: {
                required: true
            },
            valid: false,
            touched: false
        }
    }
}

function RegisterComponent() {
    const {t} = useTranslation('common');
    const [state, setState] = useState(initialState)
    const {isAuth, loading} = useSelector((state) => ({
        isAuth: !!(state.auth.userId && state.auth.token),
        loading: state.auth.loading
    }))
    const dispatch = useDispatch()

    const handleChange = (e) => {
        let updatedControls;
        if (e.target.name === 'email' || e.target.name === 'termsAndPrivacy') {
            updatedControls = {
                ...state.controls,
                [e.target.name]: {
                    ...state.controls[e.target.name],
                    value: e.target.value,
                    valid: checkValidity(e.target.value, state.controls[e.target.name].validation),
                    touched: true
                }
            };
        } else {
            updatedControls = handlePasswordChange(e);
        }
        let formIsValid = true;
        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }
        setState({controls: updatedControls, formIsValid: formIsValid});
    }

    const handlePasswordChange = (e) => {
        let updatedControls;
        if (e.target.name === 'password') {
            updatedControls = {
                ...state.controls,
                [e.target.name]: {
                    ...state.controls[e.target.name],
                    value: e.target.value,
                    valid: checkValidity(e.target.value, state.controls[e.target.name].validation),
                    touched: true
                }
            };
            if (updatedControls.password.valid) {
                updatedControls = {
                    ...updatedControls,
                    passwordRepeat: {
                        ...state.controls.passwordRepeat,
                        valid: state.controls.passwordRepeat.value === e.target.value
                    }
                }
            }
        }
        if (e.target.name === 'passwordRepeat') {
            updatedControls = {
                ...state.controls,
                [e.target.name]: {
                    ...state.controls[e.target.name],
                    value: e.target.value,
                    valid: checkValidity(e.target.value, state.controls[e.target.name].validation) && (e.target.value === state.controls.password.value),
                    touched: true
                }
            };
        }
        return updatedControls;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            email: state.controls.email.value,
            password: state.controls.password.value
        }
        dispatch(actions.register(data))
    }


    const redirect = <Redirect to={"/"}/>

    const submitButton = state.formIsValid
        ? <button type="submit" className={"btn_1 rounded full-width"}
                  onClick={handleSubmit}>{t("home.register")}</button>
        : <button type="submit" className={"btn_1_disabled rounded full-width"} disabled={true}
                  onClick={handleSubmit}>{t("home.register")}</button>

    const registerScreen = <div id="register_bg">
        <nav id="menu" className="fake_menu"/>

        <div id="login">
            <aside>
                <figure>
                    <Link to="/">
                        <img src="../assets/img/logo_sticky.svg"
                                      style={{transform: "scale(2.5)"}} width="100" height="50"
                                      alt=""
                                      className="logo_sticky"/>
                    </Link>
                </figure>
                <br/>
                <form>
                    <div className="form-group">
                        <Input type="email" name="email"
                               inputValue={state.controls.email.value}
                               handleChange={handleChange}
                               placeholder={t("home.email")}
                               errorMessage={!state.controls.email.valid && state.controls.email.touched ? t("form_validation.email") : ''}/>
                        <i className="icon_mail_alt"/>
                    </div>
                    <div className="form-group">
                        <Input type="password" id="password1" name="password"
                               inputValue={state.controls.password.value}
                               handleChange={handleChange}
                               placeholder={t("home.password")}
                               errorMessage={!state.controls.password.valid && state.controls.password.touched ? t("form_validation.password") : ''}/>
                        <i className="icon_lock_alt"/>
                    </div>
                    <div className="form-group">
                        <Input type="password" id="password2" name="passwordRepeat"
                               inputValue={state.controls.passwordRepeat.value}
                               handleChange={handleChange}
                               placeholder={t("home.confirm_password")}
                               errorMessage={!state.controls.passwordRepeat.valid && state.controls.passwordRepeat.touched ? t("form_validation.repeat_password") : ''}/>
                        <i className="icon_lock_alt"/>
                    </div>
                    <div className="form-group">
                        <div className="checkboxes float-left add_bottom_15 add_top_15">
                            <label className="container_check">
                                {t("review.i_agree")}{" "}
                                <Link to={"/privacy"}>
                                    {t("review.terms_and_conditions")}
                                </Link>
                                <input
                                    name="termsAndPrivacy"
                                    value={
                                        state.controls.termsAndPrivacy.value === "true"
                                            ? "false"
                                            : "true"
                                    }
                                    onChange={handleChange}
                                    type="checkbox"
                                />
                                <span className="checkmark"/>
                            </label>
                        </div>

                        {/*    <Input type="checkbox" id="terms_and_privacy" name="termsAndPrivacy"
                               inputValue={state.controls.passwordRepeat.value}
                               handleChange={handleChange}
                               placeholder={t("home.confirm_password")}
                               errorMessage={!state.controls.passwordRepeat.valid && state.controls.passwordRepeat.touched ? t("form_validation.repeat_password") : ''}/>
             */}
                    </div>
                    <div id="pass-info" className="clearfix"/>
                    {loading ? <Spinner/> :
                        submitButton
                    }
                    <div className="text-center add_top_10">{t("home.already_have_account")} <strong><Link
                        to="/login">{t("home.sign_in")}</Link></strong></div>
                </form>
                <div className="copy">© {new Date().getFullYear()} Rezensa</div>
            </aside>
        </div>
    </div>;

    return !isAuth ? registerScreen : redirect;
}


export default RegisterComponent;
