import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';


const initialState = {
    companies: [],
    error: null,
    totalCount: 0,
    newCompaniesNumber: 0,
    loading: false
}

const getCompaniesStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    });
}

const getCompanies = (state, action) => {
    return updateObject(state, {
        companies: action.loadMore ? state.companies.concat(action.companies) : action.companies,
        totalCount: action.totalCount,
        newCompaniesNumber: action.companies.length,
        loading: false,
    });
}

const getCompaniesFailed = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.COMPANIES_GET_START:
            return getCompaniesStart(state, action);
        case actionTypes.COMPANIES_GET:
            return getCompanies(state, action);
        case actionTypes.COMPANIES_GET_FAIL:
            return getCompaniesFailed(state, action);
        default:
            return state

    }
}

export default reducer;
