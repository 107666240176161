export const USER_EMPLOYMENT_STATUS_TYPES = {
    EMPLOYEE: "employee",
    LEADERSHIP: "leadership",
    CONTRACTOR: "contractor",
    FREELANCER: "freelancer",
    CO_OP: "co_op",
    INTERN: "intern"
}
export const USER_ROLE_TYPES = {
    CURRENT_EMPLOYEE: "current_employee",
    FORMER_EMPLOYEE: "former_employee",
    JOB_APPLICANT: "job_applicant"
}

export const USER_DEPARTMENT_TYPES = {
    ADMINISTRATION: "administration",
    PROCUREMENT: "procurement",
    DESIGN: "design",
    CONTROLLING: "controlling",
    RESEARCH: "research",
    MANAGEMENT: "management",
    IT: "it",
    LOGISTIC: "logistic",
    PRODUCT: "product",
    COMMUNICATION: "communication",
    RECRUITING: "recruiting",
    OPERATIONS: "operations",
    LEGAL: "legal",
    SALES: "sales",
    OTHER: "other"
}

export const BENEFIT_TYPES = {
    ABILITY_TO_WORK_REMOTELY: "ability_to_work_remotely",
    DESIRABLE_OFFICE_LOCATION: "desirable_office_location",
    EASY_ACCESS_TO_PUBLIC_TRANSPORTATION: "easy_access_to_public_transportation",
    EMPLOYEE_DISCOUNTS: "employee_discounts",
    EMPLOYEE_EVENTS: "employee_events",
    FLEXIBLE_WORKING_HOURS: "flexible_working_hours",
    FREE_MEALS: "free_meals",
    FREE_SNACKS_AND_DRINKS: "free_snacks_and_drinks",
    HEALTH_AND_WELLNESS_PROGRAMS: "health_and_wellness_programs",
    LIFE_INSURANCE: "life_insurance",
    ON_SITE_CAFETERIA: "on_site_cafeteria",
    ON_SITE_DAYCARE_FACILITY: "on_site_daycare_facility",
    ON_SITE_FITNESS_CENTER: "on_site_fitness_center",
    PAID_PARENTAL_LEAVE: "paid_parental_leave",
    PARKING: "parking",
    PET_FRIENDLY: "pet_friendly",
    RELOCATION_ALLOWANCE: "relocation_allowance",
    STOCK_OR_EQUITY_OPTIONS: "stock_or_equity_options",
    TUITION_ASSISTANCE: "tuition_assistance",
    VEHICLE_ALLOWANCE: "vehicle_allowance",
    VISION_AND_DENTAL_HEALTHCARE: "vision_and_dental_healthcare",
    PAID_TIME_OFF: "paid_time_off"
}

export const BENEFIT_TYPES_ICON_REPRESENTATIVE = {
    "ability_to_work_remotely": "fas fa-home",
    "desirable_office_location": "fas fa fa-map-marker",
    "easy_access_to_public_transportation": "fas fa-train ",
    "employee_discounts": "fas fa-percent",
    "employee_events": "fas fa-glass-cheers",
    "flexible_working_hours": "fas fa-clock",
    "free_meals": "fas fa-utensils",
    "free_snacks_and_drinks": "fas fa-coffee",
    "health_and_wellness_programs": "fas fa-heartbeat ",
    "life_insurance": "fas fa-hand-holding-usd",
    "on_site_cafeteria": "fas fa-hamburger",
    "on_site_daycare_facility": "fas fa-medkit",
    "on_site_fitness_center": "fas fa-dumbbell",
    "paid_parental_leave": "fas fa-baby",
    "parking": "fas fa-parking ",
    "pet_friendly": "fas fa-dog",
    "relocation_allowance": "fas fa-people-carry",
    "stock_or_equity_options": "fas fa-chart-line",
    "tuition_assistance": "fas fa-graduation-cap",
    "vehicle_allowance": "fas fa-car",
    "vision_and_dental_healthcare": "fas fa-glasses",
    "paid_time_off": "fas fa-money-check-alt"
}



export const RATING_OPTIONS = {
    COMPANY_CULTURE: "company_culture",
    COMPANY_INTERNAL_COMMUNICATION: "company_internal_communication",
    COMPANY_TEAMWORK: "company_teamwork",
    COMPANY_WORK_LIFE_BALANCE: "company_work_life_balance",
    COMPANY_SUPPORT_FROM_THE_MANAGEMENT: "company_support_from_the_management",
    COMPANY_FREEDOM_OF_INDEPENDENT_WORK: "company_freedom_of_independent_work",
    DIVERSITY_GENDER_EQUALITY: "diversity_gender_equality",
    DIVERSITY_INCLUSIVE_OR_DIVERSE: "diversity_inclusive_or_diverse",
    DIVERSITY_ATTITUDE_TOWARDS_ODER_COLLEAGUES: "diversity_attitude_towards_oder_colleagues",
    WORK_ENV_OFFICE: "work_env_office",
    WORK_ENV_FRIENDLINESS: "work_env_friendliness",
    WORK_ENV_HANDICAPPED_ACCESS: "work_env_handicapped_access",
    WORK_ENV_SAFETY: "work_env_safety",
    CAREER_COMPENSATION_FOR_WORK: "career_compensation_for_work",
    CAREER_JOB_SECURITY: "career_job_security",
    CAREER_COMPANY_IMAGE: "career_company_image",
    CAREER_DEVELOPMENT: "career_development",
    CAREER_CHALLENGING_WORK: "career_challenging_work",
}

