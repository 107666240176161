import http from "../../config/http-common";
import * as actionTypes from "./actionTypes";


export const getUserProfileStart = () => {

    return {
        type: actionTypes.USER_PROFILE_GET_START
    };
};

export const getUserProfileSuccessful = (profile) => {
    return {
        type: actionTypes.USER_PROFILE_GET,
        userInfo: profile
    }
}

export const getUserProfileFailed = (error) => {
    return {
        type: actionTypes.USER_PROFILE_FAIL,
        error: error
    }
}

export const getUserInfo = (token) => {
    return dispatch => {
        dispatch(getUserProfileStart());
        let url = `/profile?fullInfo=true`;
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        http.get(url, config)
            .then(response => {
                dispatch(getUserProfileSuccessful(response.data))
            })
            .catch(error => {
                dispatch(getUserProfileFailed(error));
            });
    }
}
