import {updateObject} from "../utility";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
    userInfo: {},
    error: null,
    loading: false
}

const getUserProfileStart = (state, action) => {
    return updateObject(state, {error: null, loading: true});
};

const getUserProfile = (state, action) => {
    return updateObject(state, {
        userInfo: action.userInfo,
        loading: false
    })
}

const getUserProfileFailed = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.USER_PROFILE_GET_START:
            return getUserProfileStart(state, action);
        case actionTypes.USER_PROFILE_GET:
            return getUserProfile(state, action);
        case actionTypes.USER_PROFILE_FAIL:
            return getUserProfileFailed(state, action);
        default:
            return state;
    }
};

export default reducer;
