import React from "react";
import { Link } from "react-router-dom";
import 'moment/locale/hr';

function CompanyCategoryHome(props) {
    return (
        props.companiesCategories.map((category, index) => (
            <div key={index} className="col-lg-4 col-sm-6">
                <Link to={"/search?category=" + category.categoryKey} className="grid_item">
                    <figure>
                        <img src={`${process.env.REACT_APP_BACKEND_URL}uploads/${category.categoryImg}`} alt="" />
                        <div className="info">
                            <h3>{category.categoryName}</h3>
                        </div>
                    </figure>
                </Link>
            </div>
        )
        )
    )
}


export default CompanyCategoryHome;
