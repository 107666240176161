import http from "../config/http-common";

class CompanyService{

    getAll(){
        return http.get("/company/all");
    }

    get(id) {
        return http.get(`/company/${id}`);
    }
    getRatingsForCompany(id) {
        return http.get(`/company/ratings/${id}`);
    }

    create(data) {
        return http.post("/company", data);
    }

    update(id, data) {
        return http.put(`/company/${id}`, data);
    }

    findByName(name) {
        return http.get(`/company/name/${name}`);
    }


}
export default new CompanyService();
