import React, {useEffect, useState} from "react";
import CompanyService from "../services/company.service";
import RatingService from "../services/rating.service";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import * as actions from "../store/actions";
import Tooltip from "./layouts/Tooltip";
import {RATING_OPTIONS} from "../constants/rating";
import {BENEFIT_TYPES_ICON_REPRESENTATIVE} from "../constants/rating";
import {Link, useHistory} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Moment from "moment";
import parse from "html-react-parser";
import {capitalizeFirstLetter, getParsedDate} from "../helpers/helper";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {useParams} from "react-router";


const CompanyComponent = (props) => {
    const initialState = {
        company: {},
        ratings: [],
        ratingsRequested: false,
        showTabOne: true,
        limit: 5
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        adaptiveHeight: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const [companyComponentState, setCompanyComponentState] = useState(initialState);

    const [similarCompanies, setSimilarCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {t, i18n} = useTranslation("common");
    const dispatch = useDispatch();
    const history = useHistory();
    const {id} = useParams();
    const {isAuth} = useSelector((state) => ({
        isAuth: state.auth.token !== null,
    }))

    const checkId = () => {
        if (id.length < 20) {
            history.push("/404");
        }
    };

    useEffect(() => {
        checkId();
        getCompanyById();
        getSimilarCompanies();
        return () => {
            document.querySelector("title").innerHTML = "Rezensa";
            document
                .querySelector("meta[name='description']")
                .setAttribute(
                    "content",
                    "Rezensa je nepristrasna platforma za ocjenjivanje rada poslodavaca na području bivše Jugoslavije. Donesite prave poslovne odluke."
                );
            document
                .querySelector("meta[property='og:title']")
                .setAttribute("content", "Rezensa");
            document
                .querySelector("meta[property='keywords']")
                .setAttribute(
                    "content",
                    "rezensa, rezensa.com, rezensa.ba, rezensa.hr, poslovni portal, firme, baza podataka, registar preduzeća, companies, registar firmi, registar kompanija, ocjena, poslodovac, rejting, ratings, firma, jugoslavija"
                );
            document
                .querySelector("meta[property='og:url']")
                .setAttribute("content", "https://rezensa.com/");
            document
                .querySelector("meta[property='og:image']")
                .setAttribute("content", "https://rezensa.com/assets/img/logo.jpg");
            document
                .querySelector("meta[property='og:description']")
                .setAttribute(
                    "content",
                    "Rezensa je nepristrasna platforma za ocjenjivanje rada poslodavaca na području bivše Jugoslavije. Donesite prave poslovne odluke."
                );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if ((similarCompanies.length !== undefined) && Object.keys(companyComponentState.company).length > 0) {
            setIsLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [similarCompanies, companyComponentState.company])
    const getSimilarCompanies = () => {
        RatingService.getSimilarCompanies(id)
            .then((similar) => {
                setSimilarCompanies(similar.data.companies);
            }).catch((error) => {
            history.push("/404");
        });
    };
    const getCompanyById = (resetRatingsAndTab = false) => {
        CompanyService.get(id)
            .then((company) => {
                let updatedState;
                if (resetRatingsAndTab) {
                    updatedState = {
                        ratingsRequested: false,
                        showTabOne: true
                    };
                }
                updatedState = {...companyComponentState, ...updatedState, company: company.data};
                let searchString = new URLSearchParams("sortBy=high_low");
                searchString.append("industry", company.data.industryType);
                searchString.append("take", "6");
                dispatch(actions.searchCompanies(searchString.toString()));
                setCompanyComponentState(updatedState);
            }).catch((error) => {
            history.push("/404");
        });
    };

    const handleLoadMore = () => {
        setCompanyComponentState({...companyComponentState, limit: companyComponentState.limit + 5});
    };
    const switchTabs = () => {
        setCompanyComponentState({...companyComponentState, showTabOne: !companyComponentState.showTabOne});
    };

    useEffect(() => {
        if (!companyComponentState.showTabOne && companyComponentState.ratingsRequested === false) {
            CompanyService.getRatingsForCompany(id)
                .then((ratings) => {
                    setCompanyComponentState({
                        ...companyComponentState,
                        ratings: ratings.data,
                        ratingsRequested: true,
                    });
                })
                .catch((error) => {
                    dispatch(
                        actions.handleNotification(error.response.data, "ERROR")
                    );
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyComponentState.showTabOne, companyComponentState.ratingsRequested])

    const totalSumOfStars =
        companyComponentState.company.fiveStars +
        companyComponentState.company.fourStars +
        companyComponentState.company.threeStars +
        companyComponentState.company.twoStars +
        companyComponentState.company.oneStars;
    const fiveStarsPercentage =
        (companyComponentState.company.fiveStars * 100) / totalSumOfStars;
    const fourStarsPercentage =
        (companyComponentState.company.fourStars * 100) / totalSumOfStars;
    const threeStarsPercentage =
        (companyComponentState.company.threeStars * 100) / totalSumOfStars;
    const twoStarsPercentage =
        (companyComponentState.company.twoStars * 100) / totalSumOfStars;
    const oneStarsPercentage =
        (companyComponentState.company.oneStars * 100) / totalSumOfStars;

    /*
    {
    "@context":"http://schema.org/",
    "@type":"BreadcrumbList",
    "itemListElement":[
        {"@type":"ListItem","item":{"@id":"https://www.kununu.com/de/daimler-truck","name":"Daimler Truck AG"},
        "position":1
        }
        ]}
    */
    const ldJsonOne = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
            {
                "@type": "ListItem",
                item: {
                    "@id": `https://rezensa.com/${companyComponentState.company._id}`,
                    name: companyComponentState.company.name,
                },
                position: 1,
            },
        ],
    };

    /*
    {
    "@context":"http://schema.org/",
    "@type":"EmployerAggregateRating",
    "bestRating":"5",
    "itemReviewed":{
        "@type":"Organization",
        "name":"Daimler Truck AG",
        "sameAs":"https://www.daimlertruck.com/karriere"
        },
        "ratingValue":4,
        "ratingCount":217,
        "worstRating":"1"
        }
    * */

    const ldJsonTwo = {
        "@context": "https://schema.org/",
        "@type": "EmployerAggregateRating",
        bestRating: "5",
        itemReviewed: {
            "@type": "Organization",
            name: companyComponentState.company.name,
            sameAs: companyComponentState.company.companyWebsite,
        },
        ratingValue: Math.round(companyComponentState.company.average * 100) / 100,
        ratingCount: companyComponentState.company.ratings ? companyComponentState.company.ratings.length : 0,
        worstRating: 1
    };

    /*
    {
    "@context":"http://schema.org/",
    "@type":"Organization",
    "aggregateRating":
        {
        "@type":"AggregateRating",
        "bestRating":"5",
        "worstRating":"1",
        "ratingValue":4,
        "ratingCount":217
        },
        "name":"Daimler Truck AG",
        "sameAs":"https://www.daimlertruck.com/karriere"
        }
    * */
    const ldJsonThree = {
        "@context": "https://schema.org/",
        "@type": "Organization",
        aggregateRating: {
            "@type": "AggregateRating",
            bestRating: "5",
            worstRating: "1",
            ratingValue: Math.round(companyComponentState.company.average * 100) / 100,
            ratingCount: companyComponentState.company.ratings ? companyComponentState.company.ratings.length : 0
        },
        name: companyComponentState.company.name,
        sameAs: companyComponentState.company.companyWebsite ? companyComponentState.company.companyWebsite : window.location.href,
    };

    return (
        <main>
            {!isLoading && (
                <>
                <HelmetProvider>
                    <Helmet>
                        <title>{capitalizeFirstLetter(companyComponentState.company.name)}</title>
                        <meta
                            name="description"
                            content={
                                companyComponentState.company.description
                                    ? companyComponentState.company.description
                                    : t("company.company_description_not_present")
                            }
                        />
                        <meta
                            name="twitter:title"
                            content={
                                companyComponentState.company.title
                                    ? capitalizeFirstLetter(companyComponentState.company.title)
                                    : t("company.company_title_not_present")
                            }
                        />
                        <meta
                            property="og:description"
                            content={
                                companyComponentState.company.description
                                    ? companyComponentState.company.description
                                    : t("company.company_description_not_present")
                            }
                        />
                        <meta property="og:title" content={capitalizeFirstLetter(companyComponentState.company.name)}/>
                        <meta property="keywords" content={
                            `${t("industry_types." + companyComponentState.company.industryType)}, ${companyComponentState.company.name}, posao , recenzija, rejting, firma, company, ocjena`
                        }
                        />
                        <meta property="og:url"
                              content={`https://rezensa.com/company/${companyComponentState.company._id}`}/>
                        <meta property="og:image"
                              content={companyComponentState.company.companyLogo ? process.env.REACT_APP_BACKEND_URL + companyComponentState.company.companyLogo : "#"}/>
                        <script type="application/ld+json">
                            {JSON.stringify(ldJsonOne)}
                        </script>
                        <script type="application/ld+json">
                            {JSON.stringify(ldJsonTwo)}
                        </script>
                        <script type="application/ld+json">
                            {JSON.stringify(ldJsonThree)}
                        </script>
                    </Helmet>
                </HelmetProvider>

                    <div className="reviews_summary">
                        <div className="wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <figure>
                                            <img
                                                src={
                                                    companyComponentState.company.companyLogo
                                                        ? process.env.REACT_APP_BACKEND_URL +
                                                        companyComponentState.company.companyLogo
                                                        : "#"
                                                }
                                                alt=""
                                            />
                                        </figure>
                                        <small>
                                            &#8226;{" "}
                                            {t("industry_types." + companyComponentState.company.industryType)}
                                        </small>
                                        <h1>{companyComponentState.company.name}</h1>
                                        {/*todo fix this to the nicer version*/}
                                        {companyComponentState.company.average ? (
                                            <span className="rating">
                        {" "}
                                                {Array.from(
                                                    Array(
                                                        Math.round(companyComponentState.company.average)
                                                    ),
                                                    (_, i) => (
                                                        <i key={i} className="icon_star"/>
                                                    )
                                                )}
                                                {Array.from(
                                                    Array(5 - Math.round(companyComponentState.company.average)),
                                                    (_, i) => (
                                                        <i key={i} className="icon_star empty"/>
                                                    )
                                                )}
                                                <em>
                          {(Math.round(companyComponentState.company.average * 100) / 100).toFixed(1)}
                        </em>
                      </span>
                                        ) : ( <div/> )}
                                    </div>
                                    <div className="col-lg-4 review_detail">
                                        <div className="row">
                                            <div className="col-lg-9 col-9">
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{ width: Math.round(fiveStarsPercentage) + "%", color: Math.round(fiveStarsPercentage) <= 5 ? "#000" : "#fff"}}>
                                                        <b>{Math.round(fiveStarsPercentage) + "%"}</b>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-3 text-right">
                                                <strong>
                                                    {t("company.number_stars", {number: 5, color: Math.round(fiveStarsPercentage) <= 5 ? "#000" : "#fff"})}
                                                </strong>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-9 col-9">
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{
                                                            width: fourStarsPercentage + "%",
                                                            color:
                                                                Math.round(fourStarsPercentage) <= 5
                                                                    ? "#000"
                                                                    : "#fff",
                                                        }}>
                                                        <b>{Math.round(fourStarsPercentage) + "%"}</b>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-3 text-right">
                                                <strong>
                                                    {t("company.number_stars", {number: 4,})}
                                                </strong>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-9 col-9">
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{
                                                            width: Math.round(threeStarsPercentage) + "%",
                                                            color:
                                                                Math.round(threeStarsPercentage) <= 5
                                                                    ? "#000"
                                                                    : "#fff",
                                                        }}
                                                    >
                                                        <b>{Math.round(threeStarsPercentage) + "%"}</b>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-3 text-right">
                                                <strong>
                                                    {t("company.number_stars", {
                                                        number: 3,
                                                    })}
                                                </strong>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-9 col-9">
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{
                                                            width: twoStarsPercentage + "%",
                                                            color:
                                                                Math.round(twoStarsPercentage) <= 5
                                                                    ? "#000"
                                                                    : "#fff",
                                                        }}
                                                    >
                                                        <b>{Math.round(twoStarsPercentage) + "%"}</b>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-3 text-right">
                                                <strong>
                                                    {t("company.number_stars", {
                                                        number: 2,
                                                    })}
                                                </strong>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-9 col-9">
                                                <div className="progress last">
                                                    <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{
                                                            width: Math.round(oneStarsPercentage) + "%",
                                                            color:
                                                                Math.round(oneStarsPercentage) <= 5
                                                                    ? "#000"
                                                                    : "#fff",
                                                        }}
                                                    >
                                                        <b>{Math.round(oneStarsPercentage) + "%"}</b>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-3 text-right">
                                                <strong>
                                                    {t("company.number_stars", {
                                                        number: 1,
                                                    })}
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container margin_tabs">
                        <div id="tabs" className="tabs">
                            <nav>
                                <ul>
                                    <li style={{cursor: "pointer"}} onClick={switchTabs}
                                        className={companyComponentState.showTabOne ? "tab-current" : ""}>
                                        <span>
                                            <i className="pe-7s-note2"/>
                                            {/*  <br/>*/}
                                            {t("company.overview")}
                                            <em>{t("company.msg1")}</em>

                                        </span>
                                    </li>
                                    <li style={{cursor: "pointer"}} onClick={switchTabs}
                                        className={!companyComponentState.showTabOne ? "tab-current" : ""}>
                                        <span>
                                            <i className="pe-7s-star"/>
                                            {t("company.ratings")}
                                            <em>{t("company.msg2")}</em>
                                        </span>
                                    </li>
                                </ul>
                            </nav>
                            <div className="content">
                                <section id="section-1"
                                         className={companyComponentState.showTabOne ? "content-current" : ""}>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-8">
                                            {companyComponentState.company.title ? (
                                                <h2>{companyComponentState.company.title}</h2>
                                            ) : (
                                                ""
                                            )}
                                            {companyComponentState.company.description ? (
                                                <div
                                                    className="jumbotron jumbotron-fluid"
                                                    style={{background: "#fff"}}
                                                >
                                                    <div className="container">
                                                        {parse(companyComponentState.company.description)}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    className="jumbotron jumbotron-fluid"
                                                    style={{background: "#fff"}}
                                                >
                                                    <div className="container">
                                                        <h1 className="display-4">
                                                            {t("company.company_description_not_present")}
                                                        </h1>
                                                        <p className="lead">
                                                            {t("company.your_company")}
                                                            {/* <a href={"/register-company/" + companyComponentState.company._id} className="lead">
                                                                 {t("company.takeover_company")}
                                                            </a>*/}
                                                            <Link
                                                                to={"/register-company/" + companyComponentState.company._id}
                                                                className="lead" replace>
                                                                {t("company.takeover_company")}
                                                            </Link>
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </section>

                                <section id="section-2"
                                         className={!companyComponentState.showTabOne ? "content-current" : ""}>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-8">
                                            {companyComponentState.ratings.length !== 0 &&
                                            companyComponentState.ratings ? (
                                                companyComponentState.ratings
                                                    .slice(0, companyComponentState.limit)
                                                    .map((rating) => (
                                                        <div className="review_card" key={rating._id}>
                                                            <div className="row">
                                                                <div className="col-md-3 user_info">
                                                                    <figure>
                                                                        <img src="../assets/img/avatar4.jpg" alt=""/>
                                                                    </figure>
                                                                    <h5>
                                                                        {t("user_role_types." + rating.userRole)}
                                                                    </h5>
                                                                    <h5>
                                                                        <em>
                                                                            {t("review.department")}: {" "}
                                                                            {t("user_department_types." + rating.userDepartment)}
                                                                        </em>
                                                                    </h5>
                                                                    <br/>
                                                                    <br/>
                                                                    <br/>
                                                                    {rating.recommendation ? (
                                                                        <span>
                                                                            {" "} <i className="far fa-smile-beam fa-3x"
                                                                                     style={{color: "#32a067"}}/>
                                                                            <br/>
                                                                            {t("company.recommends")}
                                                                        </span>
                                                                    ) : (
                                                                        <span>
                                                                            {" "}
                                                                            <i className="far fa-frown fa-3x"
                                                                               style={{color: "#cc0000"}}/>
                                                                            <br/>
                                                                            {t("company.not_recommends")}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                <hr/>
                                                                <div className="col-md-9 review_content">
                                                                    <div className="clearfix add_bottom_15">
                                                                        {/*todo Export this to component */}
                                                                        {rating.averagePerDocument ? (
                                                                            <div>
                                        <span className="rating">
                                          {" "}
                                            {Array.from(Array(Math.round(companyComponentState.company.average)),
                                                (_, i) => (
                                                    <i key={i} className="icon_star"/>
                                                )
                                            )}
                                            {Array.from(Array(5 - Math.round(companyComponentState.company.average)), (_, i) => (
                                                    <i key={i} className="icon_star empty"/>
                                                )
                                            )}
                                            <em>
                                            {Number(rating.averagePerDocument.toFixed(2))}/5.00
                                            </em>
                                        </span>
                                                                            </div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        <em>{getParsedDate(rating.createdAt)}</em>
                                                                    </div>
                                                                    <h4>{rating.headline}</h4>
                                                                </div>
                                                            </div>
                                                            <br/>
                                                            <div className="row">
                                                                {rating.likedStuffAboutCompany ? (
                                                                    <div className="col-12">
                                                                        <h5>{t("company.things_i_like")}:</h5>
                                                                        <p>{rating.likedStuffAboutCompany}</p>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {rating.dislikedStuffAboutCompany ? (
                                                                    <div className="col-12">
                                                                        <h5>{t("company.things_i_dont_like")}:</h5>
                                                                        <p>{rating.dislikedStuffAboutCompany}</p>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {rating.suggestionsForImprovement ? (
                                                                    <div className="col-12">
                                                                        <h5>
                                                                            {t("company.improvement_suggestions")}:
                                                                        </h5>
                                                                        <p>{rating.suggestionsForImprovement}</p>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>

                                                            <h5>{t("search.rating")}</h5>
                                                            {Object.values(RATING_OPTIONS).map(
                                                                (item, ratingOptionIndex) => (
                                                                    <div key={ratingOptionIndex} className="row"
                                                                         style={{marginBottom: "5px"}}>
                                                                        <div className="col-6">
                                                                            <label>
                                                                                {t("rating_options." + item)}
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <span className="rating">
                                                                                {Array.from(Array(Math.round(rating[item])), (_, i) => (
                                                                                        <i key={i} className="icon_star"/>
                                                                                    )
                                                                                )}
                                                                                {Array.from(Array(5 - Math.round(rating[item])),
                                                                                    (_, i) => (
                                                                                        <i key={i}
                                                                                           className="icon_star empty"/>
                                                                                    ))}
                                                                                <em>
                                                                                    {(Math.round(rating[item] * 100) / 100).toFixed(1)}
                                                                                </em>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            <br/>

                                                            {rating.benefits.length > 0 ? (
                                                                <div className="row">
                                                                    <h4>
                                                                        {t("company.following_benefits_offered")}
                                                                    </h4>
                                                                    <div className="col-md-12 text-center">
                                                                        {rating.benefits.map((benefit, index) => (
                                                                            <Tooltip key={index}
                                                                                     content={t("benefit_types." + benefit)}
                                                                                     direction="top">
                                        <span style={{
                                            color: "#eef3f7",
                                            background: "#FFC107",
                                            borderRadius: "3px",
                                            padding: "5px",
                                            marginBottom: "0.4rem",
                                            marginRight: "0.2rem",
                                        }}
                                              className={BENEFIT_TYPES_ICON_REPRESENTATIVE[benefit] + " fa-3x"}
                                        >
                                        </span>
                                                                            </Tooltip>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <h4>
                                                                        {t("company.user_selected_no_benefits")}
                                                                    </h4>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))
                                            ) : (
                                                <h6>{t("company.no_results")}</h6>
                                            )}

                                            {companyComponentState.company.ratings.length !== 0 &&
                                                companyComponentState.company.ratings.length >
                                                companyComponentState.limit && (
                                                    <div className="pagination__wrapper add_bottom_15">
                                                        <p className="text-center">
                                                            <button
                                                                onClick={handleLoadMore}
                                                                className="btn_1 rounded add_top_15"
                                                            >
                                                                {t("search.load_more")}
                                                            </button>
                                                        </p>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>

                    <div className="feat_blocks">
                        <div className="container-fluid h-100">
                            <div className="row h-100 justify-content-center align-items-center">
                                <div className="col-md-6 p-0">
                                    <div className="block_1">
                                        <img
                                            src="../assets/img/company_info_graphic_1.svg"
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="block_2">
                                        <h4>{t("company.leave_rating_msg1")}</h4>
                                        <p>{t("company.leave_rating_msg2")}</p>
                                        <Link
                                            to={"/review/" + companyComponentState.company._id}
                                            className="btn_1"
                                        >
                                            {t("company.leave_rating")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    {similarCompanies.length !== 0 &&
                        <div className="bg_color_1">
                            <div className="container margin_60">
                                <div className="main_title_3">
                                    <h2>{t("home.latest_review")}</h2>
                                </div>
                                <div id="recommended_reviews">
                                    <Slider {...settings}>
                                        {similarCompanies.map(
                                            (company, index) =>
                                                companyComponentState.company._id !== company._id && (
                                                    <div className="item" key={index}>
                                                        <div className="review_listing">
                                                            <div className="clearfix">
                                                                <figure>
                                                                    <img
                                                                        src={company.companyLogo ? process.env.REACT_APP_BACKEND_URL + company.companyLogo : "#"}
                                                                        alt=""/>
                                                                </figure>
                                                                <span className="rating">
                                                                  {Array.from(
                                                                      Array(Math.round(company.average)),
                                                                      (_, i) => (
                                                                          <i key={i} className="icon_star"/>
                                                                      )
                                                                  )}
                                                                  {Array.from(
                                                                        Array(5 - Math.round(company.average)),
                                                                        (_, i) => (
                                                                            <i key={i} className="icon_star empty"/>
                                                                        )
                                                                  )}
                                                                    <em>
                                                                        {(Math.round(company.average * 100) / 100).toFixed(1)}/5.0
                                                                    </em>
                                                                </span>
                                                                <small>
                                                                    {t("review.industry")}:{" "}
                                                                    {t("industry_types." + company.industryType)}
                                                                </small>
                                                            </div>
                                                            <ul className="clearfix">
                                                                <li>
                                                                    <h3>
                                                                        <strong>
                                                                            <Link to={"/company/" + company._id}>
                                                                                {company.name}
                                                                            </Link>
                                                                        </strong>
                                                                    </h3>
                                                                </li>
                                                                <li>
                                                                    <label>
                                                                        <small>
                                                                            <i className="icon-location-8"/>
                                                                            {company.city}, {company.country}
                                                                        </small>
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                            <ul className="clearfix">
                                                                <li>
                                                                    <small>
                                                                        {t("home.published") + " "}
                                                                        {i18n.language === "en"
                                                                            ? Moment(company.createdAt)
                                                                                .locale("en")
                                                                                .fromNow()
                                                                            : Moment(company.createdAt)
                                                                                .locale("hr")
                                                                                .fromNow()}
                                                                    </small>
                                                                </li>
                                                                <li>
                                                                    <Link
                                                                        to={"/company/" + company._id}
                                                                        className="btn_1 small">
                                                                        {t("company.open_company")}
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )
                                        )}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    }

                    {!isAuth &&
                        <div className="call_section_2">
                            <div className="wrapper">
                                <div className="container">
                                    <h3>{t("company.get_started_now_with_rezensa")}</h3>
                                    <Link to={"/register"} className="btn_1 medium">
                                        {t("home.join")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    }
                </>
            )}
        </main>
    );
}

export default CompanyComponent;
