import http from "../../config/http-common";
import * as actionTypes from "./actionTypes";

export const getCategoriesStart = () => {
    return {
        type: actionTypes.COMPANY_CATEGORIES_GET_START
    }
}

export const getCategoriesSuccessful = (categories) => {
    return {
        type: actionTypes.COMPANY_CATEGORIES_GET,
        categories: categories
    }
}

export const getCategoriesFailed = (error) => {
    return {
        type: actionTypes.COMPANY_CATEGORIES_FAIL,
        error: error
    }
}

export const categories = (numberOfRequests) => {
    return dispatch => {
        let url = `/company/category`;
        if (numberOfRequests) {
            url = url + `?number=` + numberOfRequests
        }
        dispatch(getCategoriesStart());

        http.get(url)
            .then(response => {
                dispatch(getCategoriesSuccessful(response.data))
            })
            .catch(error => {
                dispatch(getCategoriesFailed(error));
            });
    }
}
