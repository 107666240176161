import React from "react";
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";


function ConfirmScreen(props) {
    const { t } = useTranslation('common');
    return (
        <main className="margin_main_container">
            <div className="container margin_60">
                <div className="row justify-content-center">
                    <div className="col-md-5">

                        <div id="confirm">
                            {props.successScreen === true || props.successScreen === undefined ?
                                <div className="icon icon--order-success svg add_bottom_15">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72">
                                        <g fill="none" stroke="#8EC343" strokeWidth="2">
                                            <circle cx="36" cy="36" r="35"
                                                style={{ "strokeDasharray": "240px, 240px", "strokeDashoffset": "480px" }} />
                                            <path d="M17.417,37.778l9.93,9.909l25.444-25.393"
                                                style={{ "strokeDasharray": "50px, 50px", "strokeDashoffset": "0px" }} />
                                        </g>
                                    </svg>
                                </div>
                                : <div className="icon icon--order-success svg add_bottom_15">
                                    <img alt="Not approved icon" src="../../assets/img/close.svg" width="72" height="72" />
                                </div>
                            }
                            {/* <h2>{t(props.headerMessage)}</h2>
                            <p>{t(props.subheaderMessage)}</p> */}
                            <h2>{t("review.review_submitted")}</h2>
                            <p>{t("review.review_submitted_message")}</p>
                        </div>
                    </div>
                </div>

            </div>


            <div className="bg_color_1">
                <div className="container margin_60_35">
                    <div className="row">
                        <div className="col-lg-6">
                            <Link to={'/help'} className="boxed_list">
                                <i className="pe-7s-help2" />
                                <h4>{t("review.need_help")}</h4>
                                <p>{t("review.need_help_message")}</p>
                            </Link>
                        </div>
                        <div className="col-lg-6">
                            <Link to={'/help'}
                                className="boxed_list">
                                <i className="pe-7s-flag" />
                                <h4>{t("review.report_abuse")}</h4>
                                <p>{t("review.report_abuse_message")}</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
export default ConfirmScreen;
