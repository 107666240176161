import i18next from "i18next";
import Cookies from "js-cookie";
import common_en from "../locales/en/translation.json";
import common_bs from "../locales/bs/translation.json";

i18next.init({
    interpolation: {escapeValue: false},  // React already does escaping
    lng: Cookies.get('lang'), // language to use
    fallbackLng: 'bs',
    resources: {
        en: {
            common: common_en // 'common' is our custom namespace
        },
        bs: {
            common: common_bs
        },
    },
});
export default i18next;
