import React from 'react';
import {Editor}  from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './RichEditor.css';
import {withTranslation} from "react-i18next";

const RichEditor = (props) => {
    return (
        <div>
            <Editor
                editorState={props.editorState}
                onEditorStateChange={props.handleEditorChange}
                toolbar={{
                    options: ['blockType', 'list'],
                    inline: {
                        options: ['bold', 'italic', 'underline']
                    },
                    blockType: {
                        inDropdown: false,
                        options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"]
                    },
                    list: {
                        options: ["unordered", "ordered"]
                    }
                }}
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"/>
            <br/>
        </div>
    )
}

export default (withTranslation("common")(RichEditor));

