import React from "react";
import {useTranslation} from "react-i18next";

/*TODO need to translate this one*/
function Privacy() {
    const {t} = useTranslation('common')
    return (
        <main>
            <section className="hero_single general">
                <div className="wrapper">
                    <div className="container">
                        <h1>Rezensa - {t('privacy_policy.main_title')}</h1>
                    </div>
                </div>
            </section>
            <div className="container margin_60_35">
                <div className="row">
                    <div className="col-lg-12" id="faq">
                        <div className="add_bottom_45 accordion_2">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="mb-0">
                                        <a data-toggle="collapse"
                                           aria-expanded="true">Introdocution</a>
                                    </h5>
                                </div>

                                <div id="collapseOne_payment" className="collapse show">
                                    <div className="card-body"  dangerouslySetInnerHTML={{__html: t('privacy_policy.main_part')}}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </main>
    );
}

export default Privacy;
