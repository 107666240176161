import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    cities: [],
    error: null,
    loading: false
};

export const getCitiesStart = (state, _) => {
    return updateObject(state, {
        loading: true
    });
};

export const getCitiesSuccess = (state, action) => {
    return updateObject(state, {
        cities: action.cities,
        loading: false
    });
};

export const getCitiesFail = (state, action) => {
    return updateObject(state, {
        error: action.errors,
        loading: false
    });
};

export const getCitiesDone = () => {
    return {
        loading: false,
        error: null,
        cities: []
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CITY_GET_START:
            return getCitiesStart(state, action);
        case actionTypes.CITY_GET_SUCCESS:
            return getCitiesSuccess(state, action);
        case actionTypes.CITY_GET_FAIL:
            return getCitiesFail(state, action);
        case actionTypes.CITY_GET_DONE:
            return getCitiesDone();
        default:
            return state;
    }
};

export default reducer;
