import * as actionTypes from "./actionTypes";
import http from "../../config/http-common";
import {handleNotification} from "./notifications";

export const getCompaniesStart = () => {
    return {
        type: actionTypes.COMPANY_GET_START,
    };
};

export const getCompaniesSuccess = (data) => {
    return {
        type: actionTypes.COMPANY_GET_SUCCESS,
        companies: data
    };
};


export const getCompaniesFail = (error) => {
    return {
        type: actionTypes.COMPANY_GET_FAIL,
        error: error
    };
};

export const getCompaniesDone = () => {
    return {
        type: actionTypes.COMPANY_GET_DONE
    }
}

export const getCompanies = (name) => {
    return dispatch => {
        dispatch(getCompaniesStart());
        http.get("/company/name/"+name)
            .then(response => {
                dispatch(getCompaniesSuccess(response.data));
            })
            .catch(err => {
                dispatch(getCompaniesFail(err));
                dispatch(handleNotification(err.response.data, 'ERROR'))
            });
    };
};
