import axios from "axios";

export default axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}`,//"https://rezensa.herokuapp.com/"
    headers: {
        "Content-type": "application/json"
    },
    withCredentials: true
})

/*export default axios.create({
    baseURL: "http://localhost:3000/",
    headers: {
        "Content-type": "application/json"
    },
    withCredentials: 'same-origin'
})*/

