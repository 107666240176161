import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    categories: [],
    error: null,
    loading: false
}

const getCategoriesStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
}

const getCompaniesCategories = (state, action) => {
    return updateObject(state, {
        categories: action.categories
    })
}

const getCompaniesCategoriesFailed = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.COMPANY_CATEGORIES_GET_START:
            return getCategoriesStart(state, action);
        case actionTypes.COMPANY_CATEGORIES_GET:
            return getCompaniesCategories(state, action);
        case actionTypes.COMPANY_CATEGORIES_FAIL:
            return getCompaniesCategoriesFailed(state, action);
        default:
            return state
    }
}

export default reducer;
