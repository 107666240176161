import * as actionTypes from "./actionTypes";
import axios from "axios";

export const getCitiesStart = () => {
    return {
        type: actionTypes.CITY_GET_START,
    };
};

export const getCitiesSuccess = (data) => {
    return {
        type: actionTypes.CITY_GET_SUCCESS,
        cities: data
    };
};


export const getCitiesFail = (error) => {
    return {
        type: actionTypes.CITY_GET_FAIL,
        error: error
    };
};

export const getCitiesDone = () => {
    return {
        type: actionTypes.CITY_GET_DONE
    }
}

export const getCities = (text) => {
    return dispatch => {
        dispatch(getCitiesStart());
        const url = 'https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?query=' + text + '&country=BIH,HRV,SRB,MNE,SVN,KOS,MKD&resultType=city&language=hr&apikey=PMOxZN3nhpcVSXUfmYG9rqJOGYkIXdIOvedo1F1_jq8&app_id=8Zc7ey5Z4T34xJuGMANH';
        axios.get(url)
            .then(response => {
                dispatch(getCitiesSuccess(response.data.suggestions));
            })
            .catch(err => {
                dispatch(getCitiesFail(err));
            });
    };
};
