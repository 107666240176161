import http from "../config/http-common";

class SupportService{

    createSupportTicket(data) {
        return http.post('/support',data)
    }

    createQuestionTicket(data){
        return http.post('/question', data);
    }
}

export default new SupportService();
