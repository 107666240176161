import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import RegisterComponent from '../components/RegisterComponent'
import LoginComponent from '../components/LoginComponent'
import ReviewCreate from '../components/review/ReviewCreate'
import SearchComponent from '../components/SearchComponent'
import HomeComponent from '../components/layouts/Home'
import CompanyComponent from '../components/Company'
import NotFoundComponent from '../components/NotFoundComponent'
import UserProfileComponent from '../components/user-profile/UserProfileComponent'
import HelpComponent from '../components/layouts/Help'
import AboutUsComponent from '../components/layouts/AboutUs'
import UserSettingsComponent from '../components/user-profile/UserSettingsComponent'
import RegisterCompanyComponent from '../components/RegisterCompanyComponent'
import RestartPasswordComponent from '../components/RestartPasswordComponent'
import ConfirmEmailComponent from '../components/ConfirmEmailComponent'
import SingleReview from '../components/review/SingleReview'
import ScrollToTop from "../components/ScrollToTop";
import ConfirmScreen from "../components/review/ConfirmScreen";
import TermsAndConditions from "../components/layouts/TermsAndConditions";
import Privacy from "../components/layouts/Privacy";

function Routes(props) {
    return (
        props.mounted && (
            <div>
                <ScrollToTop />
                <Switch>
                    <PrivateRoute authed={props.isAuth} path="/profile" component={UserProfileComponent}/>
                    <PrivateRoute authed={props.isAuth} path="/settings" component={UserSettingsComponent}/>
                    <PublicRoute exact path="/register" component={RegisterComponent}/>
                    <PublicRoute exact path="/restart-password/:token" component={RestartPasswordComponent}/>
                    <PublicRoute exact path="/register-company/:id" component={RegisterCompanyComponent}/>
                    <PublicRoute exact path="/rating/:id?" component={SingleReview}/>

                    <Route path="/review/:id?" component={ReviewCreate} />
                    <Route path="/confirm" component={ConfirmScreen} />
                    <Route
                        path="/confirm-email/:token"
                        component={ConfirmEmailComponent}
                    />
                    <Route path="/search" component={SearchComponent} />
                    <Route path="/login" component={LoginComponent} />
                    <Route path="/404" component={NotFoundComponent} />
                    <Route path="/help" component={HelpComponent} />
                    <Route path="/about" component={AboutUsComponent} />
                    <Route exact path="/company/:id" component={CompanyComponent} />
                    <Route exact path="/" component={HomeComponent} />
                    <Route path="/terms" component={TermsAndConditions} />
                    <Route path="/privacy" component={Privacy} />
                    <Route component={NotFoundComponent} />
                </Switch>
            </div>
        )
    )
}

function PrivateRoute({ component: Component, authed, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                authed === true ? (
                    <Component {...props} />
                ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: props.location },
                            }}
                        />
                    )
            }
        />
    )
}

function PublicRoute({ component: Component, authed, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                authed === true ? (
                    <Redirect
                        to={{ pathname: '/', state: { from: props.location } }}
                    />
                ) : (
                        <Component {...props} />
                    )
            }
        />
    )
}

export default Routes
