export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const COMPANY_CATEGORIES_GET_START = 'COMPANY_CATEGORIES_START';
export const COMPANY_CATEGORIES_GET = 'COMPANY_CATEGORIES_GET';
export const COMPANY_CATEGORIES_FAIL = 'COMPANY_CATEGORIES_FAIL';

export const REVIEWS_GET_START = 'REVIEWS_GET_START';
export const REVIEWS_GET = 'REVIEWS_GET';
export const REVIEWS_FAIL = 'REVIEWS_FAIL';

export const REVIEW_GET_START = 'REVIEW_GET_START';
export const REVIEW_GET = 'REVIEW_GET';
export const REVIEW_FAIL = 'REVIEW_FAIL';



export const USER_PROFILE_GET_START = 'USER_PROFILE_GET_START';
export const USER_PROFILE_GET = 'USER_PROFILE_GET';
export const USER_PROFILE_FAIL = 'USER_PROFILE_FAIL';

export const COMPANIES_GET_START = 'COMPANIES_GET_START';
export const COMPANIES_GET = 'COMPANIES_GET';
export const COMPANIES_GET_FAIL = 'COMPANIES_GET_FAIL'

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

export const CITY_GET_START = 'CITY_GET_START';
export const CITY_GET_SUCCESS = 'CITY_GET_SUCCESS';
export const CITY_GET_FAIL = 'CITY_GET_FAIL';
export const CITY_GET_DONE = 'CITY_GET_DONE';

export const COMPANY_GET_START = 'COMPANY_GET_START';
export const COMPANY_GET_SUCCESS = 'COMPANY_GET_SUCCESS';
export const COMPANY_GET_FAIL = 'COMPANY_GET_FAIL';
export const COMPANY_GET_DONE = 'COMPANY_GET_DONE';
