import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes/Routes';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from './store/actions';
import {useTranslation} from 'react-i18next';
import { toast } from 'react-toastify';
import Layout from './components/layouts/Layout';

const App = () => {
    const [mounted, setMounted] = useState(false);
    const {t} = useTranslation("common");
    const {isAuth, notification} = useSelector((state) => ({
        isAuth: state.auth.token !== null,
        notification: state.notifications
    }))
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actions.authCheckState())

        setMounted(true);
        const script = document.createElement('script');
        script.src = '/assets/js/functions.js';
        script.async = false;
        document.body.appendChild(script);
    }, []);

    useEffect(() => {
        if (notification.type === 'ERROR') {
            toast.error(notification.message);
        }
        if (notification.type === 'INFO') {
            toast.info(notification.message);
        }
    }, [notification]);

    return (
        <Router>
            <Layout>
                <Routes mounted={mounted} isAuth={isAuth} />
            </Layout>
        </Router>
    );
};



export default App;
