import React from "react";
import "moment/locale/hr";
import {useTranslation, withTranslation} from "react-i18next";

function AboutUsComponent() {
    const { t } = useTranslation('common');
    return (
        <main>
            <section className="hero_single office">
                <div className="wrapper">
                    <div className="container">
                        <h1>{t("about.msg_one")}</h1>
                        <p>{t("about.msg_two")}</p>
                    </div>
                </div>
            </section>

            <div className="container margin_80">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-6">
                        <img alt="" src="../../assets/img/office_2.jpg" className="img-fluid rounded"/>
                    </div>
                    <div className="col-lg-6 pl-lg-5 pt-4">
                        <h2>{t("about.msg_three")}</h2>
                        <p className="lead">
                            {t("about.msg_four")}
                        </p>
                    </div>
                </div>
            </div>

            <div className="values">
                <div className="wrapper">
                    <div className="container">
                        <div className="main_title_2">
                            <h2>{t("about.msg_five")}</h2>
                        </div>
                        <div
                            className="row justify-content-center"
                            style={{minHeight: "210px"}}>
                            <div className="col-lg-5">
                                <div
                                    className="nav flex-column"
                                    role="tablist"
                                    aria-orientation="vertical">
                                    <a
                                        className="nav-link active"
                                        id="tab-1"
                                        data-toggle="tab"
                                        href="#tab-1-content"
                                        role="tab"
                                        aria-controls="tab-1-content"
                                        aria-selected="true">
                                        {t("about.msg_six")}
                                    </a>
                                    <a
                                        className="nav-link"
                                        id="tab-2"
                                        data-toggle="tab"
                                        href="#tab-2-content"
                                        role="tab"
                                        aria-controls="tab-2-content"
                                        aria-selected="false">
                                        {t("about.msg_seven")}
                                    </a>
                                    <a
                                        className="nav-link"
                                        id="tab-3"
                                        data-toggle="tab"
                                        href="#tab-3-content"
                                        role="tab"
                                        aria-controls="tab-3-content"
                                        aria-selected="false">
                                        {t("about.msg_eight")}
                                    </a>
                                    <a
                                        className="nav-link"
                                        id="tab-3"
                                        data-toggle="tab"
                                        href="#tab-4-content"
                                        role="tab"
                                        aria-controls="tab-4-content"
                                        aria-selected="false">
                                        {t("about.msg_nine")}
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="tab-content">
                                    <div
                                        className="tab-pane fade show active"
                                        id="tab-1-content"
                                        role="tabpanel"
                                        aria-labelledby="tab-1">
                                        <p className="lead">
                                            {t("about.msg_twelve")}
                                        </p>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="tab-2-content"
                                        role="tabpanel"
                                        aria-labelledby="tab-2">
                                        <p className="lead">
                                            {t("about.msg_thirteen")}
                                        </p>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="tab-3-content"
                                        role="tabpanel"
                                        aria-labelledby="tab-3">
                                        <p className="lead">
                                            {t("about.msg_fourteen")}
                                        </p>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="tab-4-content"
                                        role="tabpanel"
                                        aria-labelledby="tab-4">
                                        <p className="lead">
                                            {t("about.msg_fifteen")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container margin_80_55">
                <div className="main_title_2">
                    <h2>{t("about.msg_ten")}</h2>
                    <p>{t("about.msg_eleven")}</p>
                </div>
            </div>
        </main>
    );
}

export default AboutUsComponent;
