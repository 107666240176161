import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {I18nextProvider} from "react-i18next";
import './config/i18n';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose, combineReducers} from 'redux'
import thunk from 'redux-thunk';
import authReducer from './store/reducers/auth';
import categoriesReducer from './store/reducers/categories';
import reviewsReducer from './store/reducers/reviews';
import userReducer from './store/reducers/user';
import searchedCompaniesReducer from './store/reducers/searchCompanies';
import notificationReducer from './store/reducers/notifications';
import cityReducer from './store/reducers/city';
import companyAutocompleteReducer from './store/reducers/companyAutocomplete';
import i18next from "i18next";
import {ToastContainer} from "react-toastify";



const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    companiesCategories: categoriesReducer,
    reviews: reviewsReducer,
    userProfile: userReducer,
    searchedCompanies: searchedCompaniesReducer,
    notifications: notificationReducer,
    cities: cityReducer,
    companies: companyAutocompleteReducer
});

export const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <I18nextProvider i18n={i18next}>
                <ToastContainer position="bottom-left" closeButton={true}/>
                <App/>
            </I18nextProvider>
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
