import React from "react";

function Autocomplete(props){
        return (
            <div className="form-group">
                <input className="form-control" type="text"
                       placeholder={props.placeholder}
                       onChange={props.onChangeFunction}
                       onClick={() => props.click(props.name)}
                       value={props.inputValue}/>
                {props.withSearchIcon ? <i className="icon_search"/> : ''}

                {props.autoCompleteMenuVisible > 0 &&
                <ul
                    className="auto-complete-ul"
                    style={{maxHeight: "255px"}}>
                    {props.data.map((item, index) => (

                        <li key={index}
                            onClick={() => props.menuItemClicked(index, props.name)}>
                            {props.name === 'company' ? item.name : item.address.city}</li>
                    ))}

                </ul>
                }
            </div>
        )
}
export default Autocomplete;
