import React, {useEffect} from 'react'
import {Link, useHistory,} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import * as actions from '../../store/actions'
import {useLocation} from "react-router";


/*TODO this whole component needs to be adjusted as it is using for mobile screen
    jquery library and that shouldnt be a case in react. Jquery mobile menu does not update
    on react state/redux change and therefore we have a bug with not showing login or logout
    options in the menu when needed.
 */
const Header = (props) => {
    const logoutElement = document.getElementById('mm-logout');
    const bosnianElement = document.getElementById('mm-bosnianLang');
    const englishElement = document.getElementById('mm-englishLang');

    const dispatch = useDispatch()
    const {t} = useTranslation("common");
    const {isAuth} = useSelector((state) => ({
        isAuth: state.auth.token !== null,
    }))
    const location = useLocation();
    const history = useHistory()
    const handleLogOut = () => {
        dispatch(actions.logout())
        history.push('/')
    }


    useEffect(() => {
        logoutElement?.addEventListener('click', handleLogOut);
        bosnianElement?.addEventListener('click', () => props.langClick('bs'));
        englishElement?.addEventListener('click', () => props.langClick('en'));
        // Cleanup: remove the event listener on unmount
        return () => {
            logoutElement?.removeEventListener('click', handleLogOut);
            logoutElement?.removeEventListener('click', () => props.langClick('bs'));
            logoutElement?.removeEventListener('click', () => props.langClick('en'));
        };
    }, [logoutElement, bosnianElement, englishElement]);


    return location.pathname === '/login' ||
    location.pathname === '/register' ||
    location.pathname.includes('/register-company/') ||
    location.pathname.includes('/restart-password/') ? (
        <></>
    ) : (
        <header className="header_in is_fixed menu_fixed">
            <div id="logo">
                <Link to="/">
                    <img
                        src="../../assets/img/logo_sticky.svg"
                        height="40"
                        alt=""
                        style={{transform: 'scale(1.2)'}}
                        className="logo_sticky"
                    />
                </Link>
            </div>
            <ul id="top_menu">
                <li>
                    <Link to="/review" className="btn_top">
                        {t('home.write_review')}
                    </Link>
                </li>
                {!isAuth && (
                    <li>
                        <a
                            href="/login?company=true"
                            className="btn_top company">
                            {t('home.for_companies')}
                        </a>
                    </li>
                )}
                {!isAuth && (
                    <li>
                        <div
                            onClick={() => {
                                props.modalToggle()
                            }}>
                            <span className="login">
                                <i className="icon-login" style={{color: 'black'}}/>
                            </span>
                            <label className="login-text">
                                {t('home.sign_in')}
                            </label>
                        </div>
                    </li>
                )}
            </ul>

            <a href={'#'} className="btn_mobile">
                <div className="hamburger hamburger--spin" id="hamburger">
                    <div className="hamburger-box">
                        <div className="hamburger-inner"/>
                    </div>
                </div>
            </a>

            <nav id="menu" className="main-menu">
                <ul>
                    <li>
                                <span>
                                    <Link to="/">{t('home.home')}</Link>
                                </span>
                    </li>
                    <li>
                                <span>
                                    <Link to="/search">{t('home.search')}</Link>
                                </span>
                    </li>
                    <li>
                         <span>
                             {t('home.language')}
                         </span>
                        <ul>
                            <li>
                               <span id={'bosnianLang'} onClick={() => props.langClick('bs')}>
                                      Bosanski/Hrvatski/Srpski
                               </span>
                            </li>
                            <li>
                                        <span id={'englishLang'} onClick={() => props.langClick('en')}>
                                            English
                                    </span>
                            </li>
                        </ul>
                    </li>
                    {isAuth && (
                        <li>
                                    <span>
                                       {t('home.profile')}
                                    </span>
                            <ul>
                                <li>
                                    <Link to={'/profile'}>
                                        {t('home.profile_overview')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/settings'}>
                                        {t('home.settings')}
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    )}
                    {isAuth && (
                        <li id={'logout'} onClick={handleLogOut}>
                                    <span>
                                         {t('home.log_out')}
                                    </span>
                        </li>
                    )}
                    <li className="d-block d-sm-none">
                                <span>
                                    <Link to="/review" className="btn_top">
                                        {t('home.write_review')}
                                    </Link>
                                </span>
                    </li>
                </ul>
            </nav>
        </header>
    )
}


export default Header;
