/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { EditorState } from 'draft-js'
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../store/actions";
import Spinner from "../layouts/Spinner";
import { withTranslation } from "react-i18next";
import { checkValidity } from "../../shared/validation";
import Input from "../layouts/Input";
import http from "../../config/http-common";
import { convertToHTML, convertFromHTML } from 'draft-convert';
import UserProfileDeleteConfirm from "./UserProfileDeleteConfirm";
import ProfilePictureComponent from "./ProfilePictureCoponent";
import RichEditor from "./RichEditor/RichEditor";


const UserSettingsComponent = (props) => {

    const { t } = props;

    const { token, loading, profile } = useSelector(state => ({
        token: state.auth.token,
        loading: state.userProfile.loading,
        profile: state.userProfile.userInfo,
    }));

    const isMounted = useRef(false);

    const dispatch = useDispatch();

    const [usernameForm, setUserNameForm] = useState({
        username: {
            value: "",
            validation: {
                minLength: 4,
                maxLength: 30
            },
            valid: false,
            touched: false,
        }
    });

    const [editorState, setEditorState] = useState(
        () => {
            EditorState.createEmpty();
        }
    );

    const [companyTitleForm, setCompanyTitleForm] = useState({
        companyTitle: {
            value: "",
            validation: {
                minLength: 2,
                maxLength: 50
            },
            valid: true,
            touched: false,
        }
    });

    const [passwordFormIsValid, setPasswordFormIsValid] = useState(false);

    const [convertedContent, setConvertedContent] = useState({
        content: {
            value: "",
            validation: {
                maxLength: 1500
            },
            valid: true,
            touched: false
        }
    });

    ///nova validacija
    const [currentPass, setCurrentPass] = useState('');
    const [currentPassError, setCurrentPassError] = useState(null);
    const [newPass, setNewPass] = useState('');
    const [newPassError, setNewPassError] = useState(null);
    const [repeatPass, setRepeatPass] = useState('');
    const [repeatPassError, setRepeatPassError] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);


    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    const deleteAccount = () => {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        http.delete("/profile", config)
            .then((data) => {
                dispatch(actions.logout());
            })
            .catch((error) => {
                dispatch(actions.handleNotification(error.response.data, "ERROR"));
            });
    }

    const handleDrop = (file) => {
        const config = {
            headers: { Authorization: `Bearer ${token}` ,'content-type':'multipart/form-data'},
        };
        setUploadedFiles(uploadedFiles.concat(file));
        let formData = new FormData();

        formData.append("main_photo", file[0]);

        http.post("/company-picture", formData, config)
            .then(data=>{
                dispatch(actions.handleNotification(data.data.message, "INFO"));
                dispatch(actions.getUserInfo(token));
            }).catch((error) => {
                dispatch(actions.handleNotification(error.response.data, "ERROR"));
        });
    }

    const validateRepeatPass = () => {
        if (repeatPass === '') {
            setRepeatPassError(t("form_validation.required"))
            setPasswordFormIsValid(false);
        }
        else if (repeatPass.length < 7) {
            setRepeatPassError(t("form_validation.password"))
            setPasswordFormIsValid(false);
        }
        else if (repeatPass !== newPass) {
            setRepeatPassError(t("form_validation.repeat_password"))
            setPasswordFormIsValid(false);
        } else {
            setRepeatPassError(null)
            setPasswordFormIsValid(true);
        }
    }

    const validateCurrentPass = () => {
        if (currentPass === '') {
            setCurrentPassError(t("form_validation.required"))
            setPasswordFormIsValid(false);
        }
        else if (currentPass.length < 7) {
            setCurrentPassError(t("form_validation.password"))
            setPasswordFormIsValid(false);
        }
        else if (currentPass.localeCompare(newPass) === 0) {
            setCurrentPassError(t("form_validation.current_new"))
            setPasswordFormIsValid(false);
        }
        else {
            setCurrentPassError(null)
            setPasswordFormIsValid(true);
        }
    }

    const validateNewPass = () => {
        if (newPass === '') {
            setNewPassError(t("form_validation.required"))
            setPasswordFormIsValid(false);
        }
        else if (newPass.length < 7) {
            setNewPassError(t("form_validation.password"))
            setPasswordFormIsValid(false);
        }
        else if (currentPass.localeCompare(newPass) === 0) {
            setNewPassError(t("form_validation.current_new"))
            setPasswordFormIsValid(false);
        } else if (repeatPass !== newPass) {
            setNewPassError(t("form_validation.repeat_password"))
            setPasswordFormIsValid(false);
        } else {
            setNewPassError(null)
            setPasswordFormIsValid(true);
        }
    }

    const handleCurrentPassChange = (e) => {
        setCurrentPass(e.target.value)
    }

    const handleNewPassChange = (e) => {
        setNewPass(e.target.value)
    }

    const handleRepeatPassChange = (e) => {
        setRepeatPass(e.target.value)
    }

    useEffect(() => {
        if (isMounted.current) {
            validateCurrentPass();
            validateNewPass();
            validateRepeatPass();
        } else {
            isMounted.current = true;
        }
    }, [currentPass, newPass, repeatPass])


    const handleUserNameChange = (e) => {
        setUserNameForm({
            username: {
                ...usernameForm.username,
                value: e.target.value,
                touched: true,
                valid: checkValidity(e.target.value, usernameForm.username.validation)
            }
        });
    }

    const handleCompanyTitleChange = (e) => {
        setCompanyTitleForm({
            companyTitle: {
                ...companyTitleForm.companyTitle,
                value: e.target.value,
                touched: true,
                valid: checkValidity(e.target.value, companyTitleForm.companyTitle.validation)
            }
        });
    }

    useEffect(() => {
        dispatch(actions.getUserInfo(token));
    }, [dispatch, token]);

    useEffect(() => {
        //dodao uslov "profile.company[0].description" jer aplikacija pada ako nema descriptiona :)
        if (profile.company) {
            setEditorState(EditorState.createWithContent(convertFromHTML(profile.company.description || '')));
        }
    }, [profile])

    const handleSubmit = (isPasswordSection) => {
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        let data;
        if (isPasswordSection) {
            data = {
                old_password: currentPass,
                new_password: newPass,
            };
        } else {
            data = {
                username: usernameForm.username.value,
            };
        }

        if (profile.role === 'COMPANY') {
            http.put("/company-profile", data, config)
                .then((data) => {
                    dispatch(actions.handleNotification(data.data.message, "INFO"));
                })
                .catch((error) => {
                    dispatch(actions.handleNotification(error.response.data, "ERROR"));
                });
        } else {
            http.put("/user-update", data, config)
                .then((data) => {
                    dispatch(actions.getUserProfileSuccessful(data.data.user));
                    dispatch(actions.handleNotification(data.data.message, "INFO"));
                })
                .catch((error) => {
                    dispatch(actions.handleNotification(error.response.data, "ERROR"));
                });
        }
    }


    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    }

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent({
            content: {
                ...convertedContent.content,
                value: currentContentAsHTML,
                touched: true,
                valid: checkValidity(currentContentAsHTML, convertedContent.content.validation)
            }
        });
    }

    const submitDescription = () => {
        let data;
        if (companyTitleForm.companyTitle.valid) {
            data = {
                title: companyTitleForm.companyTitle.value === profile.company.name
                    ? profile.company.name : companyTitleForm.companyTitle.value
            }
        }
        if (convertedContent.content.valid) {
            data = {
                ...data,
                description: convertedContent.content.value
            }
        }
        if (data) {
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };

            http.put("/company-profile", data, config)
                .then((data) => {
                    dispatch(actions.getUserProfileSuccessful(data.data.companyProfileObject));
                    dispatch(actions.handleNotification(data.data.message, "INFO"));
                })
                .catch((error) => {
                    dispatch(actions.handleNotification(error.response.data, "ERROR"));
                });
        }
    }


    const usernameChangeButton = usernameForm.username.valid ? (
        <button
            className="btn_1 small add_top_15"
            onClick={() => handleSubmit(false)}>
            {t("user_settings.save_personal_info")}
        </button>
    ) : (
            <button
                className={"btn_1_disabled small add_top_15"}
                disabled={true}
                onClick={() => handleSubmit(false)}>
                {t("user_settings.save_personal_info")}
            </button>
        );

    const passwordChangeButton = passwordFormIsValid ? (
        <button className="btn_1 small" onClick={() => handleSubmit(true)}>
            {t("user_settings.save_password")}
        </button>
    ) : (
            <button
                disabled={true}
                className="btn_1_disabled small"
                onClick={() => handleSubmit(true)}
            >
                {t("user_settings.save_password")}
            </button>
        );

    const companyInfoChangeButton =
        <button
            className="btn_1 small add_top_15"
            onClick={() => submitDescription()}>
            {t("user_settings.save_info")}
        </button>;

    let userSummary;
    let firstSettingsPanel, profilePicturePanel;
    if (profile.role === 'COMPANY') {
        userSummary =
            <div className="user_summary">
                <div className="wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <figure>
                                    <img src={process.env.REACT_APP_BACKEND_URL + profile.company.companyLogo} alt="" />
                                </figure>
                                <h1>{profile.firstName + ' ' + profile.lastName}</h1>
                                <h5 style={{ color: "#FFF" }}>{profile.company.title || ''}</h5>
                            </div>
                            <div className="col-md-6">
                                <ul>
                                    <li>
                                        <strong>
                                            {profile.company ? profile.ratings.length : 0}
                                        </strong>
                                        <a href="#0"
                                            className="tooltips"
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title="Reviews written by you">
                                            <i className="icon_star" />{" "}
                                            {t("search.rating")}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        firstSettingsPanel =
            <div className="settings_panel">
                <h3>{t("user_settings.personal_settings")}</h3>
                <hr />
                <div className="form-group">
                    <label>{t("user_settings.edit_company_title")}</label>
                    {/*TODO edit this properly*/}
                    <Input
                        type="text"
                        name="companyTitle"
                        inputValue={companyTitleForm.value}
                        handleChange={e => handleCompanyTitleChange(e)}
                        placeholder={profile.company.title || ''}
                        errorMessage={
                            !companyTitleForm.companyTitle.valid &&
                                companyTitleForm.companyTitle.touched
                                ? t("form_validation.edit_company_title")
                                : ""
                        }
                    />
                </div>
                <br />
                <div className="form-group">
                    <label>{t("user_settings.edit_company_description")}</label>
                    {/*This is buggy, gives a console error*/}
                    <RichEditor
                        handleEditorChange={handleEditorChange}
                        editorState={editorState}
                        convertContentToHTML={convertContentToHTML} />

                    {!convertedContent.content.valid &&
                        convertedContent.content.touched ?
                        <div className="text-danger">
                            <p>{t("form_validation.company_description")}</p>
                        </div> : ''
                    }
                </div>
                <p className="text-right">
                    {companyInfoChangeButton}
                </p>
            </div>

        profilePicturePanel =
            <div className="settings_panel">
                <h3>{t("user_settings.logo_section")}</h3>
                <hr />
                <ProfilePictureComponent handleDrop={handleDrop}/>
                <br/>
            </div>
    } else {
        userSummary =
            <div className="user_summary">
                <div className="wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <figure>
                                    <img src="../../assets/img/avatar4.jpg" alt="" />
                                </figure>
                                <h1>{profile.role === 'USER' || profile.role === 'ADMIN'  ? profile.username : profile.firstName + ' ' + profile.lastName}</h1>
                            </div>
                            <div className="col-md-6">
                                <ul>
                                    <li>
                                        <strong>
                                            {profile.ratings ? profile.ratings.length : 0}
                                        </strong>
                                        <a href="#0"
                                            className="tooltips"
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title="Reviews written by you">
                                            <i className="icon_star" />{" "}
                                            {t("user_settings.reviews")}
                                        </a>
                                    </li>
                                    {/* <li>
                                        <strong>
                                            {profile.companies ? profile.companies.length : 0}
                                        </strong>
                                        <a
                                            href="#0"
                                            className="tooltips"
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title={t("user_settings.reviews")}>
                                            <i className="icon-user-1" />
                                            {t("user_settings.companies")}
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        firstSettingsPanel =
            <div className="settings_panel">
                <h3>{t("user_settings.personal_settings")}</h3>
                <hr />
                <div className="form-group">
                    <label>{t("user_settings.edit_email")}</label>
                    <input
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder={profile.email}
                        readOnly={true}
                    />
                </div>
                <div className="form-group">
                    <label>{t("user_settings.edit_username")}</label>
                    <Input
                        type="text"
                        name="username"
                        inputValue={usernameForm.value}
                        handleChange={e => handleUserNameChange(e)}
                        placeholder={profile.username}
                        errorMessage={
                            !usernameForm.username.valid &&
                                usernameForm.username.touched
                                ? t("form_validation.edit_username")
                                : ""
                        }
                    />
                </div>
                <p className="text-right">{usernameChangeButton}</p>
            </div>
    }

    return (
        <>
            {loading ? (
                <Spinner />
            ) : (
                    <main className="margin_main_container">
                        {userSummary}
                        <div className="container margin_60_35">
                            <div className="row">
                                <div className="col-lg-8">

                                    {firstSettingsPanel}

                                    {profilePicturePanel}

                                    {/*Password section*/}
                                    <div className="settings_panel">
                                        <form onSubmit={(e) => e.preventDefault()}>
                                            <h3>{t("user_settings.change_password")}</h3>
                                            <hr />
                                            <div className="form-group">
                                                <label>{t("user_settings.current_password")}</label>
                                                <Input
                                                    type="password"
                                                    name="currentPassword"
                                                    inputValue={currentPass}
                                                    handleChange={handleCurrentPassChange}
                                                    onBlur={validateCurrentPass}
                                                    placeholder={t("user_settings.enter_current_password")}
                                                    specialProps={{ autoComplete: "on" }}
                                                    errorMessage={
                                                        currentPassError !== null ? currentPassError : null
                                                    }
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>{t("user_settings.new_password")}</label>
                                                <Input
                                                    type="password"
                                                    name="newPassword"
                                                    inputValue={newPass}
                                                    handleChange={handleNewPassChange}
                                                    onBlur={validateNewPass}
                                                    placeholder={t("user_settings.enter_new_password")}
                                                    specialProps={{ autoComplete: "on" }}
                                                    errorMessage={
                                                        newPassError !== null ? newPassError : null
                                                    }
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>{t("user_settings.confirm_password")}</label>
                                                <Input
                                                    type="password"
                                                    name="passwordRepeat"
                                                    inputValue={repeatPass}
                                                    handleChange={handleRepeatPassChange}
                                                    onBlur={validateRepeatPass}
                                                    placeholder={t("home.confirm_password")}
                                                    specialProps={{ autoComplete: "on" }}
                                                    errorMessage={
                                                        repeatPassError !== null ? repeatPassError : null
                                                    }
                                                />

                                            </div>
                                            <div id="pass-info" className="clearfix" />
                                            <p className="text-right">{passwordChangeButton}</p>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-4" id="sidebar">
                                    <div className="box_general">
                                        <h5>{t("user_settings.delete_account")}</h5>
                                        <p>{t("user_settings.delete_account_message")}</p>
                                        <button className="btn_1_danger small" onClick={() => toggleModal()}>
                                            {t("user_settings.delete_account")}
                                        </button>
                                    </div>
                                    <UserProfileDeleteConfirm onRequestClose={true} handleDelete={deleteAccount} isOpen={isOpen} modalToggle={toggleModal}/>
                                </div>
                            </div>
                        </div>
                    </main>
                )}
        </>
    );

}


export default (withTranslation("common")(UserSettingsComponent));
