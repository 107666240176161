import React from "react";
import Moment from 'moment';
import 'moment/locale/hr';
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    adaptiveHeight: true,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

function RatingHome(props) {
    const { t } = props;

    return (
        <Slider {...settings} >
            {
                props.reviews.map((rating, index) => (
                    <div className="item" key={index}>
                        <div className="review_listing">
                            <div className="clearfix">
                                <figure><img src="../../assets/img/avatar1.jpg" alt="" /></figure>
                                <span className="rating">
                                    {Array.from(Array(Math.round(rating.average)), (_, i) => <i key={i}
                                        className="icon_star" />)}
                                    {Array.from(Array(5 - Math.round(rating.average)), (_, i) => <i key={i}
                                        className="icon_star empty" />)}
                                    <em>{(Math.round(rating.average * 100) / 100).toFixed(1)}/5.0</em>
                                </span>
                                <small>{t("industry_types." + rating.company.industryType)}</small>
                            </div>
                            <h3><strong>{t("home.user")}</strong> {t("home.has_reviewed")} <Link
                                to={'/company/' + rating.company._id}>{rating.company.name}</Link></h3>
                            <h4>{rating.userEmploymentStatus ? t("user_employment_status_types." + rating.userEmploymentStatus) : t("user_role_types." + rating.userRole)}</h4>
                            <ul className="clearfix">
                                <li><small>{t("home.published") + ' '}
                                    {props.i18n.language === "en" ? Moment(rating.createdAt).locale('en').fromNow()
                                        : Moment(rating.createdAt).locale('hr').fromNow()}</small>
                                </li>
                                <li><Link to={'/rating/' + rating._id}
                                    className="btn_1 small">{t("home.read_review")}</Link></li>
                            </ul>
                        </div>
                    </div>
                )
                )
            }
        </Slider>
    )
}

export default withTranslation('common')(RatingHome);
