import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import * as actions from '../store/actions/index';
import { connect } from 'react-redux';
import Spinner from "./layouts/Spinner";
import Input from "./layouts/Input";
import { checkValidity } from "../shared/validation";

class LoginComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loginAsCompany: false,
            formIsValid: false,
            controls: {
                email: {
                    value: '',
                    validation: {
                        isEmail: true,
                        required: true
                    },
                    valid: false,
                    touched: false
                },
                password: {
                    value: '',
                    validation: {
                        required: true,
                        minLength: 7
                    },
                    valid: false,
                    touched: false
                }
            }
        }
    }

    componentDidMount() {
        if (this.props.location.search.includes('company'))
            this.setState({ loginAsCompany: true })
        else
            this.setState({ loginAsCompany: false })
    }

    handleChange = (e) => {
        const updatedControls = {
            ...this.state.controls,
            [e.target.name]: {
                ...this.state.controls[e.target.name],
                value: e.target.value,
                valid: checkValidity(e.target.value, this.state.controls[e.target.name].validation),
                touched: true
            }
        };
        let formIsValid = true;
        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }
        this.setState({ controls: updatedControls, formIsValid: formIsValid });
    }

    handleSubmit = () => {
        this.props.onAuth(this.state.controls.email.value, this.state.controls.password.value, false, this.state.loginAsCompany);
    }

    switchTabs = () => {
        this.setState({
            loginAsCompany: !this.state.loginAsCompany
        })
    }

    render() {
        const { t } = this.props;
        let authRedirect = null;

        if (this.props.isAuth) {
            authRedirect = <Redirect to="/" />
        }

        const submitButton = this.state.formIsValid
            ? <button type="submit" className={"btn_1 rounded full-width"}
                onClick={this.handleSubmit}>{t("home.sign_in")}</button>
            : <button type="submit" className={"btn_1_disabled rounded full-width"} disabled={true}
                onClick={this.handleSubmit}>{t("home.sign_in")}</button>

        const loginForm = <form autoComplete="off">
            <div className="form-group">
                <Input type="email" name="email"
                    inputValue={this.state.controls.email.value}
                    handleChange={this.handleChange}
                    placeholder={t("home.email")}
                    errorMessage={!this.state.controls.email.valid && this.state.controls.email.touched ? t("form_validation.email") : ''} />
                <i className="icon_mail_alt" />
            </div>
            <div className="form-group">
                <Input type="password" id="password1" name="password"
                    inputValue={this.state.controls.password.value}
                    handleChange={this.handleChange}
                    placeholder={t("home.password")}
                    errorMessage={!this.state.controls.password.valid && this.state.controls.password.touched ? t("form_validation.password") : ''} />
                <i className="icon_lock_alt" />
            </div>
            <div id="pass-info" className="clearfix" />
            {this.props.loading ? <Spinner /> :
                submitButton
            }


            <div className="text-center add_top_10">{t("home.dont_have_account")} <strong>
                <Link to="/register">{t("home.sign_up")}</Link></strong></div>
        </form>


        return (
            <div id="login_bg">
                {authRedirect}
                <nav id="menu" className="fake_menu" />
                <div id="login">
                    <aside>
                        <figure>
                            <Link to="/">
                                <img src="../assets/img/logo_sticky.svg"
                                    style={{ transform: "scale(2.5)" }} width="100"
                                    height="50"
                                    alt=""
                                    className="logo_sticky" />
                            </Link>
                        </figure>
                        <br />
                        <ul className="nav nav-tabs">
                            <li className="nav-item" onClick={this.switchTabs}>
                                <a href="#0" className={`nav-link ${this.state.loginAsCompany ? "" : "active"}`}><strong>{t("login.login_as_user")}</strong></a>
                            </li>
                            <li className="nav-item" onClick={this.switchTabs}>
                                <a href="#0" className={`nav-link ${this.state.loginAsCompany ? "active" : ""}`}><strong>{t("login.login_as_company")}</strong></a>
                            </li>
                        </ul>
                        <div className="tab-content" style={{ paddingTop: "5rem" }}>
                            <div className="tab-pane show active"
                                aria-labelledby="profile-tab">
                                {loginForm}
                            </div>
                        </div>
                        <div className="copy">© {new Date().getFullYear()} Rezensa</div>
                    </aside>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password, rememberMe, loginAsCompany) => dispatch(actions.auth(email, password, rememberMe, loginAsCompany))
    };
};

const mapStateToProps = state => {
    return {
        isAuth: state.auth.token !== null,
        loading: state.auth.loading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(LoginComponent));
