import http from "../config/http-common";

class RatingService {

    getReviews(numberOfRequested) {
        if (numberOfRequested)
            return http.get('/rating?number=' + numberOfRequested);
        else
            return http.get('/rating');
    }

    getRatingById(id) {
        return http.get(`/rating/${id}`);
    }

    getSimilarCompanies(id) {
        return http.get(`/rating/similar/${id}`);
    }

    createReviewAndCompany(data, token) {
        if (token) {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            return http.post('/company/rating', data, config)
        }
        return http.post('/company/rating', data)
    }

    createReviewForExistingCompany(data, token) {
        if (token) {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            return http.post('/create-rating-for-company', data, config);
        }
        return http.post('/create-rating-for-company', data);
    }
}

export default new RatingService();
