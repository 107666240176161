import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    reviews: [],
    review: {},
    error: null,
    loading: false
}

const getReviewsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
}
const getReviewStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
}

const getReviews = (state, action) => {
    return updateObject(state, {
        reviews: action.reviews
    })
}
const getReview = (state, action) => {
    return updateObject(state, {
        review: action.review
    })
}

const getReviewsFailed = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};
const getReviewFailed = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REVIEWS_GET_START:
            return getReviewsStart(state, action)
        case actionTypes.REVIEW_GET_START:
            return getReviewStart(state, action)
        case actionTypes.REVIEWS_GET:
            return getReviews(state, action);
        case actionTypes.REVIEW_GET:
            return getReview(state, action);
        case actionTypes.REVIEWS_FAIL:
            return getReviewsFailed(state, action);
        case actionTypes.REVIEW_FAIL:
            return getReviewFailed(state, action);
        default:
            return state
    }
}

export default reducer;
