import React, { Component, createRef } from "react";
import Autocomplete from "../layouts/Autocomplete";
import RatingService from "../../services/rating.service";
import * as RatingEnums from "../../constants/rating";
import * as CompanyEnums from "../../constants/company";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import { checkValidity } from "../../shared/validation";
import Input from "../layouts/Input";
import { Link } from "react-router-dom";
import CompanyService from "../../services/company.service";

export class ReviewCreate extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = createRef();
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      controls: {
        benefitTypesButtonIndex: [],
        employmentStatusButtonIndex: {
          value: "",
          index: -1,
          valid: false,
        },
        userRoleTypesButtonIndex: {
          value: "",
          index: -1,
          valid: false,
        },
        userDepartment: {
          value: "",
          valid: false,
          touched: false,
          validation: {
            required: true,
          },
        },
        name: {
          value: "",
          valid: false,
          touched: false,
          validation: {
            required: true,
            maxLength: 50,
            minLength: 3,
          },
        },
        industry: {
          value: "",
          touched: false,
          valid: false,
          validation: {
            required: true,
          },
        },
        likedStuff: {
          value: "",
          valid: true,
          touched: false,
          validation: {
            maxLength: 500,
          },
        },
        dislikedStuff: {
          value: "",
          valid: true,
          touched: false,
          validation: {
            maxLength: 500,
          },
        },
        email: {
          value: "",
          valid: false,
          touched: false,
          validation: {
            required: true,
            isEmail: true,
          },
        },
        improvementSuggestion: {
          value: "",
          valid: true,
          touched: false,
          validation: {
            maxLength: 500,
          },
        },
        reviewTitle: {
          value: "",
          valid: false,
          touched: false,
          validation: {
            required: true,
            maxLength: 70,
            minLength: 5,
          },
        },
        ratingValues: Object.entries(RatingEnums.RATING_OPTIONS).map(
            (item, index) => ({
              fieldName: item[0].toLowerCase(),
              fieldValue: 0,
              valid: false,
            })
        ),
        recommendationButtonIndex: {
          value: "",
          index: -1,
          valid: false,
        },
        privacyPolicy: {
          value: "false",
          valid: false,
          touched: false,
          validation: {
            required: true,
            equalTo: "true",
          },
        },
      },
      autocomplete: {
        company: {
          companyName: "",
          autoCompleteMenuVisible: false,
          autoCompleteComponentClicked: false,
          selectedCompany: {
            name: "",
            id: "",
          },
          valid: false,
          touched: false,
        },
        city: {
          selectedCity: {
            name: "",
            locationId: "",
            country: "",
          },
          cityName: "",
          autoCompleteMenuVisible: false,
          autoCompleteComponentClicked: false,
          valid: false,
          touched: false,
        },
      },
      cantFindCompany: false,
      formIsValid: false,
      formSubmitted: false,
      showSuccessScreen: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    if (this.props.match.params.id) {
      CompanyService.get(this.props.match.params.id)
          .then((response) => {
            const updatedAutocomplete = {
              ...this.state.autocomplete,
              company: {
                ...this.state.autocomplete.company,
                selectedCompany: {
                  id: response.data._id,
                  name: response.data.name,
                },
                autoCompleteMenuVisible: false,
                valid: true,
              },
            };
            const formIsValid = this.checkIfFormIsValid({
              controls: { ...this.state.controls },
              autocomplete: updatedAutocomplete,
            });

            this.setState({
              autocomplete: updatedAutocomplete,
              formIsValid: formIsValid,
            });
          })
          .catch((error) => {
            this.props.onNotify(error.response.data, "ERROR");
          });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.controls.email.validation.required) {
      if (this.props.isAuth) {
        this.setState({
          controls: {
            ...this.state.controls,
            email: {
              ...this.state.controls.email,
              valid: true,
              validation: {
                required: false,
              },
            },
          },
        });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (!this.state.showSuccessScreen) {
      if (this.wrapperRef.current !== null) {
        if (
            this.wrapperRef &&
            !this.wrapperRef.current.contains(event.target)
        ) {
          let updatedAutocompleteOptions;
          let shouldClose = false;
          if (this.state.autocomplete.company.autoCompleteMenuVisible) {
            updatedAutocompleteOptions = {
              ...this.state.autocomplete,
              company: {
                ...this.state.autocomplete.company,
                autoCompleteMenuVisible: false,
              },
            };
            shouldClose = true;
          }
          if (this.state.autocomplete.city.autoCompleteMenuVisible) {
            updatedAutocompleteOptions = {
              ...this.state.autocomplete,
              city: {
                ...this.state.autocomplete.city,
                autoCompleteMenuVisible: false,
              },
            };
            if (
                this.state.autocomplete.city.cityName.toUpperCase() ===
                this.props.cities[0].address.city.toUpperCase()
            ) {
              updatedAutocompleteOptions = {
                ...updatedAutocompleteOptions,
                city: {
                  ...updatedAutocompleteOptions.city,
                  selectedCity: {
                    name: this.props.cities[0].address.city,
                    locationId: this.props.cities[0].locationId,
                    country: this.mapThreeISOToTwoCode(
                        this.props.cities[0].countryCode
                    ),
                  },
                  valid: true,
                },
              };
            }
            shouldClose = true;
          }

          if (shouldClose) {
            this.setState({ autocomplete: updatedAutocompleteOptions });
          }
        }
      }
    }
  }

  handleChangeOfCityName = (e) => {
    let updatedAutocomplete = {
      ...this.state.autocomplete,
      city: {
        ...this.state.autocomplete.city,
        cityName: e.target.value,
        touched: true,
        selectedCity: {
          name: "",
          locationId: "",
          country: "",
        },
      },
    };
    if (e.target.value.length > 0) {
      this.props.onCitySearch(e.target.value);
      updatedAutocomplete = {
        ...updatedAutocomplete,
        city: {
          ...updatedAutocomplete.city,
          autoCompleteMenuVisible: true,
        },
      };
    } else {
      updatedAutocomplete = {
        ...updatedAutocomplete,
        city: {
          ...updatedAutocomplete.city,
          autoCompleteMenuVisible: false,
          cityName: "",
        },
      };
    }
    this.setState({ autocomplete: updatedAutocomplete });
  };

  handleChangeOfCompanyName = (e) => {
    let updatedAutocomplete = {
      ...this.state.autocomplete,
      company: {
        ...this.state.autocomplete.company,
        companyName: e.target.value,
        touched: true,
        selectedCompany: {
          name: "",
          id: "",
        },
      },
    };

    if (e.target.value.length > 0) {
      this.props.onCompanySearch(e.target.value);
      updatedAutocomplete = {
        ...updatedAutocomplete,
        company: {
          ...updatedAutocomplete.company,
          autoCompleteMenuVisible: true,
        },
      };
    } else {
      updatedAutocomplete = {
        ...updatedAutocomplete,
        company: {
          ...updatedAutocomplete.company,
          companyName: e.target.value,
          autoCompleteMenuVisible: false,
        },
      };
    }
    this.setState({ autocomplete: updatedAutocomplete });
  };

  handleAutocompleteClick = (name) => {
    let updatedAutocomplete = {
      autocomplete: {
        ...this.state.autocomplete,
        [name]: {
          ...this.state.autocomplete[name],
          autoCompleteMenuVisible: true,
          autoCompleteComponentClicked: true,
        },
      },
    };
    this.setState({ updatedAutocomplete });
  };

  handleCompanySelect = (index) => {
    const updatedAutocomplete = {
      ...this.state.autocomplete,
      company: {
        ...this.state.autocomplete.company,
        selectedCompany: {
          id: this.props.companies[index]._id,
          name: this.props.companies[index].name,
        },
        autoCompleteMenuVisible: false,
        valid: true,
      },
    };
    const formIsValid = this.checkIfFormIsValid({
      controls: { ...this.state.controls },
      autocomplete: updatedAutocomplete,
    });

    this.setState({
      autocomplete: updatedAutocomplete,
      formIsValid: formIsValid,
    });
  };

  mapThreeISOToTwoCode(countryCode) {
    switch (countryCode) {
      case "SRB":
        return "RS";
      case "HRV":
        return "HR";
      case "BIH":
        return "BA";
      case "SVN":
        return "SI";
      case "MKD":
        return "MK";
      case "MNE":
        return "ME";
      case "KOS":
        return "XK";
      default:
        return "BA";
    }
  }

  handleCitySelect = (index) => {
    let country = this.mapThreeISOToTwoCode(
        this.props.cities[index].countryCode
    );
    const updatedAutocomplete = {
      ...this.state.autocomplete,
      city: {
        ...this.state.autocomplete.city,
        autoCompleteMenuVisible: false,
        selectedCity: {
          name: this.props.cities[index].address.city,
          locationId: this.props.cities[index].locationId,
          country: country,
        },
        valid: true,
      },
    };
    const formIsValid = this.checkIfFormIsValid({
      controls: { ...this.state.controls },
      autocomplete: updatedAutocomplete,
    });

    this.setState({
      autocomplete: updatedAutocomplete,
      formIsValid: formIsValid,
    });
  };

  checkIfFormIsValid = (updatedState) => {
    let formIsValid = true;
    for (let inputIdentifier in updatedState.controls) {
      //benefitTypesButtonIndex is skipped as it is not required
      //rating values need to be validated separately
      if (
          inputIdentifier.toString() !== "benefitTypesButtonIndex" &&
          inputIdentifier.toString() !== "ratingValues"
      ) {
        if (!this.state.cantFindCompany) {
          if (
              inputIdentifier.toString() !== "industry" &&
              inputIdentifier.toString() !== "name"
          ) {
            formIsValid =
                updatedState.controls[inputIdentifier].valid &&
                formIsValid &&
                updatedState.autocomplete.company.valid;
          }
        } else {
          formIsValid =
              updatedState.controls[inputIdentifier].valid &&
              formIsValid &&
              updatedState.autocomplete.city.valid;
        }
      }
    }
    updatedState.controls.ratingValues.forEach((rating) => {
      formIsValid = rating.valid && formIsValid;
    });
    return formIsValid;
  };

  handleChange = (e, rating) => {
    let updatedControls;
    if (rating) {
      const index = this.state.controls.ratingValues.findIndex(
          (rating) => rating.fieldName === e.target.name
          ),
          ratings = [...this.state.controls.ratingValues];
      ratings[index] = {
        ...ratings[index],
        fieldValue: e.target.value,
        valid: true,
      };
      updatedControls = {
        ...this.state.controls,
        ratingValues: ratings,
      };
    } else {
      updatedControls = {
        ...this.state.controls,
        [e.target.name]: {
          ...this.state.controls[e.target.name],
          value: e.target.value,
          valid: checkValidity(
              e.target.value,
              this.state.controls[e.target.name].validation
          ),
          touched: true,
        },
      };
    }

    const formIsValid = this.checkIfFormIsValid({
      controls: updatedControls,
      autocomplete: { ...this.state.autocomplete },
    });
    this.setState({ controls: updatedControls, formIsValid: formIsValid });
  };

  handleButtonOnClick = (item, index, stateProperty) => {
    switch (stateProperty) {
      case "userRoleTypesButtonIndex":

          //if job_applicant then employmentStatusButtonIndex is valid
          if (index===2){
            this.setState({
              controls: {
                ...this.state.controls,
                userRoleTypesButtonIndex: {
                  value: item,
                  index: index,
                  valid: true,
                },
              employmentStatusButtonIndex: {
                valid: true
              }
              }
            });
          }else
            this.setState({
              controls: {
                ...this.state.controls,
                userRoleTypesButtonIndex: {
                  value: item,
                  index: index,
                  valid: true,
                }
              }
            });
        break;
      case "benefitTypesButtonIndex":
        //check if already benefit button has been clicked
        const benefit = this.state.controls.benefitTypesButtonIndex.filter(
            (benefit) => benefit.index === index
        );
        if (benefit.length === 0) {
          //if not, add it to state
          this.setState({
            controls: {
              ...this.state.controls,
              benefitTypesButtonIndex: [
                ...this.state.controls.benefitTypesButtonIndex,
                {
                  value: item,
                  index: index,
                },
              ],
            },
          });
        } else {
          this.setState((prevState) => {
            const tasks = prevState.controls.benefitTypesButtonIndex.filter(
                (value) => value.index !== benefit[0].index
            );
            return {
              controls: {
                ...prevState.controls,
                benefitTypesButtonIndex: tasks,
              },
            };
          });
        }
        break;
      case "recommendationButtonIndex":
        this.setState({
          controls: {
            ...this.state.controls,
            recommendationButtonIndex: {
              value: item,
              index: index,
              valid: true,
            },
          },
        });
        break;
      case "employmentStatusButtonIndex":
        this.setState({
          controls: {
            ...this.state.controls,
            employmentStatusButtonIndex: {
              value: item,
              index: index,
              valid: true,
            },
          },
        });
        break;

      default:
        break;
    }
  };

  sendRequestIfValidated = () => {
    this.setState({ formSubmitted: true }, this.handleSubmit);
  };

  handleSubmit = () => {
    if (this.state.formSubmitted && this.state.formIsValid) {
      const ratings = Object.assign(
          {},
          ...this.state.controls.ratingValues.map((value) => ({
            [value.fieldName]: value.fieldValue,
          }))
      );

      let generalObject = {
        email: this.state.controls.email.value,
        user_role: this.state.controls.userRoleTypesButtonIndex.value,
        user_employment_status: this.state.controls.employmentStatusButtonIndex
            .value,
        user_department: this.state.controls.userDepartment.value,
        headline: this.state.controls.reviewTitle.value,
        recommendation: this.state.controls.recommendationButtonIndex.value,
      };
      if (this.state.controls.benefitTypesButtonIndex.length > 0)
        generalObject.benefits = this.state.controls.benefitTypesButtonIndex.map(
            (value) => value.value
        );
      if (this.state.controls.dislikedStuff.value !== "")
        generalObject.disliked_stuff_about_company = this.state.controls.dislikedStuff.value;
      if (this.state.controls.likedStuff.value !== "")
        generalObject.liked_stuff_about_company = this.state.controls.likedStuff.value;
      if (this.state.controls.improvementSuggestion.value !== "")
        generalObject.suggestions_for_improvement = this.state.controls.improvementSuggestion.value;

      //if new company
      if (this.state.cantFindCompany) {
        const objectToSend = {
          name: this.state.controls.name.value,
          city: this.state.autocomplete.city.selectedCity.name,
          country: this.state.autocomplete.city.selectedCity.country,
          industry_type: this.state.controls.industry.value,
          ...ratings,
          ...generalObject,
        };

        RatingService.createReviewAndCompany(objectToSend, this.props.token)
            .then((_) => {
              this.setState({
                showSuccessScreen: true,
              });
            })
            .catch((error) => {
              this.props.onNotify(error.response.data, "ERROR");
            });
      } else {
        const objectToSend = {
          company_id: this.state.autocomplete.company.selectedCompany.id,
          ...ratings,
          ...generalObject,
        };
        RatingService.createReviewForExistingCompany(
            objectToSend,
            this.props.token
        )
            .then((_) => {
              this.setState({
                showSuccessScreen: true,
              });
            })
            .catch((error) => {
              this.props.onNotify(error.response.data, "ERROR");
            });
      }
    }
  };

  handleCantFindCompany = () => {
    this.setState({
      cantFindCompany: !this.state.cantFindCompany,
    });
  };

  render() {
    const { t } = this.props;
    const employmentStatus = (
        <div className="form-group">
          <label className="d-block">{t("review.employment_status")}</label>
          <div className="btn-group btn-group-toggle d-flex flex-wrap mb-4" data-toggle="buttons">
            {Object.values(RatingEnums.USER_EMPLOYMENT_STATUS_TYPES).map(
                (item, index) => (
                    <label
                        key={index}
                        className="btn btn_1 flex-fill p-3"
                        onClick={() =>
                            this.handleButtonOnClick(
                                item,
                                index,
                                "employmentStatusButtonIndex"
                            )
                        }
                        style={
                          this.state.controls.employmentStatusButtonIndex.index ===
                          index
                              ? {
                                background: "#FFC107",
                                color: "black",
                              }
                              : {}
                        }
                    >
                      <input type="radio" name="options" />{" "}
                      {t("user_employment_status_types." + item)}
                    </label>
                )
            )}
          </div>
          <div className="text-danger">
            {!this.state.controls.employmentStatusButtonIndex.valid &&
            this.state.formSubmitted
                ? t("form_validation.employment_status")
                : ""}
          </div>
        </div>
    );

    const selectCompanyDiv = (
        <div>
          <Autocomplete
              onChangeFunction={this.handleChangeOfCompanyName}
              placeholder={t("home.type_company_name")}
              inputValue={
                this.state.autocomplete.company.selectedCompany.name.length > 0
                    ? this.state.autocomplete.company.selectedCompany.name
                    : this.state.autocomplete.company.companyName
              }
              autoCompleteMenuVisible={
                this.state.autocomplete.company.autoCompleteMenuVisible
              }
              data={this.props.companies}
              menuItemClicked={this.handleCompanySelect}
              name={"company"}
              click={this.handleAutocompleteClick}
              withSearchIcon={false}
          />
          <div className="text-danger">
            {!this.state.autocomplete.company.valid &&
            (this.state.autocomplete.company.touched || this.state.formSubmitted)
                ? t("form_validation.company_autocomplete")
                : ""}
          </div>
          <button className="btn btn-light" onClick={this.handleCantFindCompany}>
            {t("review.cant_find_company")}
          </button>
        </div>
    );

    const selectCity = (
        <div>
          <Autocomplete
              onChangeFunction={this.handleChangeOfCityName}
              placeholder={t("review.city")}
              inputValue={
                this.state.autocomplete.city.selectedCity.name.length > 0
                    ? this.state.autocomplete.city.selectedCity.name
                    : this.state.autocomplete.city.cityName
              }
              autoCompleteMenuVisible={
                this.state.autocomplete.city.autoCompleteMenuVisible
              }
              data={this.props.cities}
              name={"city"}
              menuItemClicked={this.handleCitySelect}
              click={this.handleAutocompleteClick}
              withSearchIcon={false}
          />
          <div className="text-danger">
            {!this.state.autocomplete.city.valid &&
            (this.state.autocomplete.city.touched || this.state.formSubmitted)
                ? t("form_validation.city_autocomplete")
                : ""}
          </div>
        </div>
    );

    const createCompanyDiv = (
        <div>
          <div className="form-group">
            <Input
                type="text"
                name="name"
                inputValue={this.state.controls.name.value}
                handleChange={this.handleChange}
                placeholder={t("home.type_company_name")}
                errorMessage={
                  !this.state.controls.name.valid &&
                  (this.state.controls.name.touched || this.state.formSubmitted)
                      ? t("form_validation.company_name")
                      : ""
                }
            />
            <br />
            <div className="row">
              <div className="col-12">{selectCity}</div>
            </div>
            <br />
            <div className="form-group">
              <label>{t("review.industry")}</label>
              <select
                  className="form-control"
                  onChange={this.handleChange}
                  name="industry"
              >
                <option>{t("review.please_select")}</option>
                {Object.values(CompanyEnums.COMPANY_INDUSTRIES).map(
                    (item, index) => (
                        <option key={index} value={item}>
                          {t("industry_types." + item)}
                        </option>
                    )
                )}
              </select>
              <div className="text-danger">
                {!this.state.controls.industry.valid && this.state.formSubmitted
                    ? t("form_validation.industry_types")
                    : ""}
              </div>
            </div>
          </div>
          <button className="btn btn-light" onClick={this.handleCantFindCompany}>
            {t("review.select_another_cmpy")}
          </button>
        </div>
    );

    const mainScreen = (
        <main className="margin_main_container">
          <div className="container margin_60_35">
            <div className="row">
              <div className="col-lg-9">
                <div className="box_general write_review">
                  <h1>{t("review.headline")}</h1>
                  <p>{t("review.sub_headline")}</p>
                  <div className="rating_submit">
                    <div className="form-group" ref={this.wrapperRef}>
                      {this.state.cantFindCompany
                          ? createCompanyDiv
                          : selectCompanyDiv}
                      <br />

                      <label className="d-block">{t("review.i_am")}</label>
                      <div
                          className="btn-group btn-group-toggle d-flex flex-wrap mb-4"
                          data-toggle="buttons"
                      >
                        {Object.values(RatingEnums.USER_ROLE_TYPES).map(
                            (item, index) => (
                                <label
                                    onClick={() =>
                                        this.handleButtonOnClick(
                                            item,
                                            index,
                                            "userRoleTypesButtonIndex"
                                        )
                                    }
                                    key={index}
                                    className="btn btn_1 flex-fill p-3"
                                    style={
                                      this.state.controls.userRoleTypesButtonIndex
                                          .index === index
                                          ? {
                                            background: "#FFC107",
                                            color: "black",
                                          }
                                          : {}
                                    }
                                >
                                  <input
                                      key={index}
                                      type="radio"
                                      name="options"
                                      autoComplete="off"
                                  />{" "}
                                  {t("user_role_types." + item)}
                                </label>
                            )
                        )}
                      </div>
                      <div className="text-danger">
                        {!this.state.controls.userRoleTypesButtonIndex.valid &&
                        this.state.formSubmitted
                            ? t("form_validation.user_role")
                            : ""}
                      </div>
                      {/*Remove if Job applicant is selected*/}
                      <br />
                      <br />
                      {this.state.controls.userRoleTypesButtonIndex.index === 2
                          ? ""
                          : employmentStatus}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{t("review.department")}</label>
                    <select
                        className="form-control"
                        onChange={this.handleChange}
                        name="userDepartment"
                    >
                      <option>{t("review.please_select")}</option>
                      {Object.values(RatingEnums.USER_DEPARTMENT_TYPES).map(
                          (item, index) => (
                              <option key={index} value={item}>
                                {t("user_department_types." + item)}
                              </option>
                          )
                      )}
                    </select>
                    <div className="text-danger">
                      {!this.state.controls.userDepartment.valid &&
                      this.state.formSubmitted
                          ? t("form_validation.user_department")
                          : ""}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{t("review.title")}</label>
                    <Input
                        type="text"
                        name="reviewTitle"
                        inputValue={this.state.controls.reviewTitle.value}
                        handleChange={this.handleChange}
                        placeholder={t("review.msg1")}
                        errorMessage={
                          !this.state.controls.reviewTitle.valid &&
                          (this.state.controls.reviewTitle.touched ||
                              this.state.formSubmitted)
                              ? t("form_validation.review_title")
                              : ""
                        }
                    />

                    {/* <input className="form-control" type="text"
                                               name="reviewTitle"
                                               value={this.state.controls.reviewTitle.value}
                                               onChange={this.handleChange}
                                               placeholder={t("review.msg1")}/>*/}
                  </div>
                  <div className="col-12" style={{ background: "#eef3f7" }}>
                    <p>{t("review.msg2")}</p>
                    <ul>
                      <li>{t("review.msg3")}</li>
                      <li>{t("review.msg4")}</li>
                      <li>{t("review.msg5")}</li>
                    </ul>
                  </div>
                  {this.state.controls.ratingValues.map((item, index) => (
                      <div className="col-12" key={index}>
                        <label
                            className={`d-block ${
                                item.valid === false &&
                                this.state.formSubmitted === true
                                    ? "text-danger"
                                    : ""
                            }`}
                        >
                          {t("rating_options." + item.fieldName)}
                        </label>
                        <span className="rating">
                      <input
                          type="radio"
                          className="rating-input"
                          id={index + "5_star"}
                          name={item.fieldName}
                          value={5}
                          onChange={(event) => this.handleChange(event, true)}
                      />
                      <label
                          htmlFor={index + "5_star"}
                          className="rating-star ml-3 ml-md-5"
                      />
                      <input
                          type="radio"
                          className="rating-input"
                          id={index + "4_star"}
                          name={item.fieldName}
                          onChange={(event) => this.handleChange(event, true)}
                          value={4}
                      />
                      <label
                          htmlFor={index + "4_star"}
                          className="rating-star ml-3 ml-md-5"
                      />
                      <input
                          type="radio"
                          className="rating-input"
                          id={index + "3_star"}
                          name={item.fieldName}
                          onChange={(event) => this.handleChange(event, true)}
                          value={3}
                      />
                      <label
                          htmlFor={index + "3_star"}
                          className="rating-star ml-3 ml-md-5"
                      />
                      <input
                          type="radio"
                          className="rating-input"
                          id={index + "2_star"}
                          name={item.fieldName}
                          onChange={(event) => this.handleChange(event, true)}
                          value={2}
                      />
                      <label
                          htmlFor={index + "2_star"}
                          className="rating-star ml-3 ml-md-5"
                      />
                      <input
                          type="radio"
                          className="rating-input"
                          id={index + "1_star"}
                          name={item.fieldName}
                          onChange={(event) => this.handleChange(event, true)}
                          value={1}
                      />
                      <label
                          htmlFor={index + "1_star"}
                          className="rating-star ml-3 ml-md-5"
                      />
                    </span>
                      </div>
                  ))}
                  <br />
                  <br />
                  <div className="col-12" style={{ background: "#eef3f7" }}>
                    <h3>{t("review.section_headline")}</h3>
                  </div>
                  <br />
                  <br />
                  <div className="form-group">
                    <label>{t("review.question1")}</label>
                    <textarea
                        className="form-control"
                        name="improvementSuggestion"
                        value={this.state.controls.improvementSuggestion.value}
                        onChange={this.handleChange}
                        style={{ height: "180px" }}
                        placeholder={t("review.improvements_suggestions")}
                    />
                    <div className="text-danger">
                      {!this.state.controls.improvementSuggestion.valid &&
                      this.state.controls.improvementSuggestion.touched
                          ? t("form_validation.improvements_suggestions")
                          : ""}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{t("review.question2")}</label>
                    <textarea
                        className="form-control"
                        name="likedStuff"
                        value={this.state.controls.likedStuff.value}
                        onChange={this.handleChange}
                        style={{ height: "180px" }}
                        placeholder={t("review.things_that_you_like")}
                    />
                    <div className="text-danger">
                      {!this.state.controls.likedStuff.valid &&
                      this.state.controls.likedStuff.touched
                          ? t("form_validation.liked_stuff")
                          : ""}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{t("review.question3")}</label>
                    <textarea
                        className="form-control"
                        name="dislikedStuff"
                        value={this.state.controls.dislikedStuff.value}
                        onChange={this.handleChange}
                        style={{ height: "180px" }}
                        placeholder={t("review.things_that_you_dislike")}
                    />
                    <div className="text-danger">
                      {!this.state.controls.dislikedStuff.valid &&
                      this.state.controls.dislikedStuff.touched
                          ? t("form_validation.disliked_stuff")
                          : ""}
                    </div>
                  </div>

                  <label className="d-block">{t("review.recommend")}</label>
                  <div
                      className="btn-group btn-group-toggle"
                      data-toggle="buttons"
                  >
                    <label
                        className="btn btn_1"
                        style={
                          this.state.controls.recommendationButtonIndex.index === 0
                              ? {
                                background: "#FFC107",
                                color: "black",
                              }
                              : {}
                        }
                        onClick={() =>
                            this.handleButtonOnClick(
                                1,
                                0,
                                "recommendationButtonIndex"
                            )
                        }
                    >
                      <input
                          type="radio"
                          name="recommendation"
                          autoComplete="off"
                      />
                      {t("review.yes")}
                    </label>
                    <label
                        className="btn btn_1"
                        style={
                          this.state.controls.recommendationButtonIndex.index === 1
                              ? {
                                background: "#FFC107",
                                color: "black",
                                marginLeft: "0.5em",
                              }
                              : { marginLeft: "0.5em" }
                        }
                        onClick={() =>
                            this.handleButtonOnClick(
                                0,
                                1,
                                "recommendationButtonIndex"
                            )
                        }
                    >
                      <input type="radio" name="recommendation" value="0" />
                      {t("review.no")}
                    </label>
                  </div>

                  <div className="text-danger">
                    {!this.state.controls.recommendationButtonIndex.valid &&
                    this.state.formSubmitted
                        ? t("form_validation.recommendation")
                        : ""}
                  </div>
                  <br />
                  <br />
                  <div className="form-group">
                    <label className="d-block">{t("review.question4")}</label>
                    <div
                        className="btn-group btn-group-toggle d-flex flex-wrap mb-4"
                        data-toggle="buttons"
                    >
                      {Object.values(RatingEnums.BENEFIT_TYPES).map(
                          (item, index) => (
                              <label
                                  onClick={() =>
                                      this.handleButtonOnClick(
                                          item,
                                          index,
                                          "benefitTypesButtonIndex"
                                      )
                                  }
                                  key={index}
                                  className="btn btn_1 flex-fill p-3"
                                  style={
                                    this.state.controls.benefitTypesButtonIndex.filter(
                                        (benefit) => benefit.index === index
                                    ).length === 0
                                        ? {}
                                        : {
                                          background: "#FFC107",
                                          color: "black",
                                        }
                                  }
                              >
                                <input type="radio" name="options" />{" "}
                                {t("benefit_types." + item)}
                              </label>
                          )
                      )}
                    </div>
                  </div>

                  {this.props.isAuth ? (
                      ""
                  ) : (
                      <div className="form-group">
                        <label className="d-block">
                          <i className="icon-email" /> {t("review.email")}
                        </label>

                        <Input
                            type="email"
                            name="email"
                            inputValue={this.state.controls.email.value}
                            handleChange={this.handleChange}
                            placeholder={t("review.email_type")}
                            errorMessage={
                              !this.state.controls.email.valid &&
                              (this.state.controls.email.touched ||
                                  this.state.formSubmitted)
                                  ? t("form_validation.email")
                                  : ""
                            }
                        />
                      </div>
                  )}

                  <div className="row">
                    <div className="col-md-12">
                      <div className="checkboxes float-left add_bottom_15 add_top_15">
                        <label className="container_check">
                          {t("review.i_agree")}{" "}
                          <Link to={"/privacy"}>
                            {t("review.terms_and_conditions")}
                          </Link>
                          <input
                              name="privacyPolicy"
                              value={
                                this.state.controls.privacyPolicy.value === "true"
                                    ? "false"
                                    : "true"
                              }
                              onChange={this.handleChange}
                              type="checkbox"
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                    <div className="text-danger">
                      {!this.state.controls.privacyPolicy.valid &&
                      (this.state.controls.privacyPolicy.touched ||
                          this.state.formSubmitted)
                          ? t("form_validation.privacy_policy")
                          : ""}
                    </div>
                  </div>
                  <button
                      className="btn_1 m-2"
                      onClick={this.sendRequestIfValidated}
                  >
                    {t("review.submit")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
    );

    return (
        <>
          {this.state.showSuccessScreen
              ? this.props.history.push("/confirm")
              : mainScreen}
        </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onRegister: (data) => dispatch(actions.register(data)),
    onCitySearch: (text) => dispatch(actions.getCities(text)),
    onCompanySearch: (name) => dispatch(actions.getCompanies(name)),
    onNotify: (message, type) =>
        dispatch(actions.handleNotification(message, type)),
  };
};

const mapStateToProps = (state) => {
  return {
    isAuth: !!(state.auth.userId && state.auth.token),
    token: state.auth.token,
    errors: state.auth.errors,
    loading: state.auth.loading,
    cities: state.cities.cities,
    companies: state.companies.companies,
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("common")(ReviewCreate));
