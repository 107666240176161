import * as actionTypes from './actionTypes';
import http from "../../config/http-common";
import { handleNotification } from "./notifications";

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, userId) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        userId: userId
    };
};

export const authFail = () => {
    return {
        type: actionTypes.AUTH_FAIL
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');

    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const auth = (email, password, rememberMe = false, loginAsCompany = false) => {
    return dispatch => {
        dispatch(authStart());

        const authData = {
            email: email,
            password: password,
            rememberMe: rememberMe
        };

        let url = '';
        if (loginAsCompany)
            url = '/login/company';
        else
            url = '/login';

        http.post(url, authData)
            .then(response => {
                const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('userId', response.data._id)
                localStorage.setItem('expirationDate', expirationDate);
                dispatch(authSuccess(response.data.token, response.data._id));
                dispatch(checkAuthTimeout(response.data.expiresIn));
                dispatch(handleNotification(response.data.message, 'INFO'))
            })
            .catch(err => {
                if (err.response.data){
                dispatch(authFail(err.response.data.error));
                dispatch(handleNotification(err.response.data, 'ERROR'))
                } else{
                    dispatch(authFail('Error happened during login'));
                    dispatch(handleNotification('Error happened during login', 'ERROR'))
                }
            });
    };
};

export const register = (data) => {
    return dispatch => {
        dispatch(authStart());
        http.post('/register', data)
            .then(response => {
                const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('userId', response.data.user._id)
                localStorage.setItem('expirationDate', expirationDate);
                dispatch(authSuccess(response.data.token, response.data.user._id));
                dispatch(checkAuthTimeout(response.data.expiresIn));
                dispatch(handleNotification(response.data.message, 'INFO'))
            })
            .catch(err => {
                dispatch(authFail());
                dispatch(handleNotification(err.response.data, 'ERROR'))
            });
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        const id = localStorage.getItem('userId');
        if (!token || !id) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                dispatch(authSuccess(token, id));
            }
        }
    }
}

