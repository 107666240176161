import * as actionTypes from './actionTypes';

export const showNotification = (data, type) => {
    if(type==='ERROR')
        return {
        type: actionTypes.SHOW_NOTIFICATION,
        message: data.message,
        notificationType: type
    };
    if (type==='INFO')
        return {
            type: actionTypes.SHOW_NOTIFICATION,
            message: data,
            notificationType: type
        };
};

export const hideNotification = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch( {
                type: actionTypes.HIDE_NOTIFICATION
            });
        },  500);
    };
};

export const handleNotification = (message, type) => {
    return dispatch => {
        dispatch(showNotification(message, type));
        dispatch(hideNotification());
    };
};
