export const COMPANY_INDUSTRIES = {
    AUTOMOTIVE : "automotive",
    BANKING_AND_INVESTMENT_MANAGEMENT : "banking_and_investment_management",
    BROADCAST_MEDIA_AND_PRODUCTION : "broadcast_media_and_production",
    CHEMICALS : "chemicals",
    CIVIC_AND_SOCIAL_ORGANIZATIONS : "civic_and_social_organizations",
    CONSTRUCTION_AND_ARCHITECTURE : "construction_and_architecture",
    CONSULTING : "consulting",
    CONSUMER_ELECTRONICS : "consumer_electronics",
    EDUCATION : "education",
    ENERGY : "energy",
    ENTERTAINMENT_AND_ART : "entertainment_and_art",
    EVENT_PLANNING_AND_MANAGEMENT : "event_planning_and_management",
    FINANCIAL_SERVICES_AND_ACCOUNTING : "financial_services_and_accounting",
    FOOD_PRODUCTION_AND_FARMING : "food_production_and_farming",
    GOVERNMENT_AND_MILITARY : "government_and_military",
    HEALTH_AND_FITNESS : "health_and_fitness",
    HOSPITALS_AND_HEALTHCARE : "hospitals_and_healthcare",
    INSURANCE : "insurance",
    INTERNET : "internet",
    LAW_PRACTICES_AND_LEGAL_SERVICES : "law_practices_and_legal_services",
    MANUFACTURING : "manufacturing",
    MARKET_RESEARCH : "market_research",
    MARKETING_ADVERTISING_AND_PR : "marketing_advertising_and_pr",
    MECHANICAL_AND_INDUSTRIAL_ENGINEERING : "mechanical_and_industrial_engineering",
    MEDICAL_DEVICES_AND_TECHNOLOGY : "medical_devices_and_technology",
    PHARMACEUTICALS_AND_BIOTECH : "pharmaceuticals_and_biotech",
    PRINTING_AND_PACKAGING : "printing_and_packaging",
    PURCHASING : "purchasing",
    REAL_ESTATE_AND_FACILITIES_SERVICES : "real_estate_and_facilities_services",
    RESEARCH : "research",
    RESTAURANTS_AND_HOTELS : "restaurants_and_hotels",
    RETAIL_AND_CONSUMER_GOODS : "retail_and_consumer_goods",
    TRADES : "trades",
    SOFTWARE_AND_INFORMATION_TECHNOLOGY : "software_and_information_technology",
    STAFFING_AND_RECRUITING : "staffing_and_recruiting",
    SUPPORT_AND_SERVICE : "support_and_service",
    TAX_CONSULTING : "tax_consulting",
    TELECOMMUNICATIONS : "telecommunications",
    TEXTILES_AND_DESIGN : "textiles_and_design",
    TRANSPORTATION_AND_LOGISTICS : "transportation_and_logistics",
    OTHER_INDUSTRIES : "other_industries",
}

export const COUNTRIES = {
    bosnia_and_herzegovina : "BA",
    croatia : "HR",
    kosovo : "XK",
    macedonia : "MK",
    montenegro : "ME",
    serbia : "RS",
    slovenia : "SI"
}

