import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import Spinner from "./layouts/Spinner";
import { checkValidity } from "../shared/validation";
import Input from "./layouts/Input";
import CompanyProfileService from "../services/company-profile.service";

const initialState = {
    formIsValid: false,
    controls: {
        firstName: {
            value: "",
            validation: {
                required: true,
                minLength: 3,
                maxLength: 50
            },
            valid: false,
            touched: false
        },
        lastName: {
            value: "",
            validation: {
                required: true,
                minLength: 3,
                maxLength: 50
            },
            valid: false,
            touched: false
        },
        phoneNumber: {
            value: "",
            validation: {
                isPhoneNumber: true,
                required: true
            },
            valid: false,
            touched: false
        },
        email: {
            value: "",
            validation: {
                isEmail: true,
                required: true
            },
            valid: false,
            touched: false
        },
    }
};

class RegisterCompanyComponent extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = { ...initialState };
    }

    handleProfileChange = () => {
        this.props.onProfileChange();
    }

    handleChange = (e) => {
        let updatedControls;
        updatedControls = {
            ...this.state.controls,
            [e.target.name]: {
                ...this.state.controls[e.target.name],
                value: e.target.value,
                valid: checkValidity(e.target.value, this.state.controls[e.target.name].validation),
                touched: true
            }
        };

        let formIsValid = true;
        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }
        this.setState({ controls: updatedControls, formIsValid: formIsValid });
    }

    handleSubmit(e) {
        e.preventDefault();

        const data = {
            firstName: this.state.controls.firstName.value,
            lastName: this.state.controls.lastName.value,
            companyId: this.props.match.params.id,
            phoneNumber: this.state.controls.phoneNumber.value,
            email: this.state.controls.email.value
        }
        CompanyProfileService.createCompanyProfileInquiry(data)
            .then(data => {
                this.props.onNotify(data.data.message, "INFO");
                this.setState({ ...initialState });
            }).catch(error => {
                this.props.onNotify(error.response.data, "ERROR")
            });
    }


    render() {
        const { t } = this.props;
        const redirect = <Redirect to={"/"} />

        const submitButton = this.state.formIsValid
            ? <button type="submit" className={"btn_1 rounded full-width"}
                onClick={this.handleSubmit}>{t("help.submit")}</button>
            : <button type="submit" className={"btn_1_disabled rounded full-width"} disabled={true}
                onClick={this.handleSubmit}>{t("help.submit")}</button>

        const registerScreen =
            <div id="register_bg">
                <nav id="menu" className="fake_menu" />
                <div id="login">
                    <aside>
                        <figure>
                            <Link to="/">
                                <img src="../assets/img/logo_sticky.svg"
                                    style={{ transform: "scale(2.5)" }} width="100" height="50"
                                    alt=""
                                    className="logo_sticky" />
                            </Link>
                        </figure>
                        <br />
                        <form>
                            <div className="form-group">
                                <Input type="text" name="firstName"
                                    inputValue={this.state.controls.firstName.value}
                                    handleChange={this.handleChange}
                                    placeholder={t("help.first_name")}
                                    errorMessage={!this.state.controls.firstName.valid && this.state.controls.firstName.touched ? t("form_validation.first_name") : ''} />
                                <i className="icon_profile" />
                            </div>
                            <div className="form-group">
                                <Input type="text" name="lastName"
                                    inputValue={this.state.controls.lastName.value}
                                    handleChange={this.handleChange}
                                    placeholder={t("help.last_name")}
                                    errorMessage={!this.state.controls.lastName.valid && this.state.controls.lastName.touched ? t("form_validation.last_name") : ''} />
                                <i className="icon_profile" />
                            </div>
                            <div className="form-group">
                                <Input type="text" name="phoneNumber"
                                    inputValue={this.state.controls.phoneNumber.value}
                                    handleChange={this.handleChange}
                                    placeholder={t("help.phone")}
                                    errorMessage={!this.state.controls.phoneNumber.valid && this.state.controls.phoneNumber.touched ? t("form_validation.phone") : ''} />
                                <i className="icon_phone" />
                            </div>
                            <div className="form-group">
                                <Input type="email" name="email"
                                    inputValue={this.state.controls.email.value}
                                    handleChange={this.handleChange}
                                    placeholder={t("home.email")}
                                    errorMessage={!this.state.controls.email.valid && this.state.controls.email.touched ? t("form_validation.email") : ''} />
                                <i className="icon_mail_alt" />
                            </div>

                            <div id="pass-info" className="clearfix" />
                            {this.props.loading ? <Spinner /> :
                                submitButton
                            }
                            <div className="text-center add_top_10">{t("company_registration.already_have_account_connected_company")} <strong><a href="/login?company=true" onClick={this.handleProfileChange}>Prijava</a></strong></div>

                        </form>
                        <div className="copy">© {new Date().getFullYear()} Rezensa</div>
                    </aside>
                </div>
            </div>;

        return !this.props.isAuth ? registerScreen : redirect;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onNotify: (message, type) => dispatch(actions.handleNotification(message, type)),
        onProfileChange: () => dispatch(actions.logout())
    };
};

export default connect(null, mapDispatchToProps)(withTranslation('common')(RegisterCompanyComponent));
