import React, {useState} from "react";
import Header from "./Header";
import Footer from "./Footer";
import SignInPopUp from "./SignInPopUp";
import Cookies from "js-cookie";
import i18next from "i18next";
import {Helmet, HelmetProvider} from 'react-helmet-async';


function Layout(props) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    try {
        const changeLanguage = (lang) => {
            /*TODO remove this when appropiate*/
            Cookies.set("lang", lang, {
                path: "/",
                expires: 7,
                secure: true,
                domain: process.env.NODE_ENV === 'development' ? 'localhost' : '.rezensa.com'
            });
            i18next.changeLanguage(lang);
        };


        const metaTags =
            <HelmetProvider>
                <Helmet>
                    <title>{"Rezensa"}</title>
                    <meta name="description"
                          content="Rezensa je nepristrasna platforma za ocjenjivanje rada poslodavaca na području bivše Jugoslavije. Donesite prave poslovne odluke."/>
                    <meta property="og:description"
                          content="Rezensa je nepristrasna platforma za ocjenjivanje rada poslodavaca na području bivše Jugoslavije. Donesite prave poslovne odluke."/>
                    <meta property="og:title" content={"Rezensa"}/>
                    <meta property="keywords"
                          content={"rezensa, rezensa.com, rezensa.ba, rezensa.hr, poslovni portal, firme, baza podataka, registar preduzeća, companies, registar firmi, registar kompanija, ocjena, poslodovac, rejting, ratings, firma"}/>
                    <meta property="og:url" content={"https://rezensa.com/"}/>
                    <meta property="og:image" content={"https://rezensa.com/assets/img/logo.jpg"}/>
                </Helmet>
            </HelmetProvider>

        return (
            <div>
                {metaTags}
                <div id="page">
                    <Header
                        langClick={changeLanguage}
                        modalToggle={toggleModal}
                        isOpen={isOpen}/>
                    {props.children}
                    <Footer/>
                </div>
                <SignInPopUp isOpen={isOpen} modalToggle={toggleModal}/>
                <div id="toTop"/>
            </div>
        );
    } catch (error) {
        console.log(error);
    }
}

export default Layout;
