import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router";

const Footer = () => {
        const {t} = useTranslation("common");
        const location = useLocation();

        return location.pathname === '/login' ||
        location.pathname === '/register' ||
        location.pathname.includes('/register-company/') ||
        location.pathname.includes('/restart-password/') ? (
            <></>
        ) : (
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <a href="#0" data-toggle="collapse" data-target="#collapse_ft_1" aria-expanded="false"
                                aria-controls="collapse_ft_1" className="collapse_bt_mobile">
                                <h3>{t("home.quick_links")}</h3>
                                <div className="circle-plus closed">
                                    <div className="horizontal" />
                                    <div className="vertical" />
                                </div>
                            </a>
                            <div className="collapse show" id="collapse_ft_1">
                                <ul className="links">
                                    <li><Link to="/about">{t("home.about_us")}</Link></li>
                                    <li><Link to="/help">{t("home.help_footer")}</Link></li>
                                    <li><a href="/profile">{t("home.my_account")}</a></li>
                                    <li><a href="/register">{t("home.create_account")}</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <a href="#0" data-toggle="collapse" data-target="#collapse_ft_3" aria-expanded="false"
                                aria-controls="collapse_ft_3" className="collapse_bt_mobile">
                                <h3>{t("home.contacts")}</h3>
                                <div className="circle-plus closed">
                                    <div className="horizontal" />
                                    <div className="vertical" />
                                </div>
                            </a>
                            <div className="collapse show" id="collapse_ft_3">
                                <ul className="contacts">
                                    <li><i className="ti-home" />71000 Alojza Benca 4<br />Sarajevo - BiH</li>
                                   {/* <li><i className="ti-headphone-alt" />+387 62 222 373</li>*/}
                                    <li><i className="ti-email" /><Link to='#' onClick={() => window.location = 'mailto:support@rezensa.ba'}>support@rezensa.ba</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <a href="#0" data-toggle="collapse" data-target="#collapse_ft_4" aria-expanded="false"
                                aria-controls="collapse_ft_4" className="collapse_bt_mobile">
                                <h3>{t("home.follow_us")}</h3>
                                <div className="circle-plus closed">
                                    <div className="horizontal" />
                                    <div className="vertical" />
                                </div>
                            </a>
                            <div className="collapse show" id="collapse_ft_4">
                                <div className="follow_us">
                                    <ul>
                                        <li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/rezensa"><i className="ti-facebook" /></a></li>
                                        <li><a href="https://www.instagram.com/?hl=en"><i className="ti-instagram" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">

                        <div className="col-lg-12">
                            <ul id="additional_links">
                                <li><Link to="/terms">{t("home.terms_and_conditions")}</Link></li>
                                <li><Link to="/privacy">{t("home.privacy")}</Link></li>
                                <li><span>© {new Date().getFullYear()} Rezensa</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        );
}
export default Footer;
